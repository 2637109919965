import React, { useState } from 'react';
import { Modal, Alert, Button, Form } from 'react-bootstrap';

export const CancelSubscriptionModal = ({
    show,
    onHide,
    error,
    cancelReason,
    onReasonChange,
    loading,
    onConfirm
}) => {
    const [isConfirmed, setIsConfirmed] = useState(false);

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Cancel Subscription</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && <Alert variant="danger">{error}</Alert>}
                <p>Are you sure you want to cancel your subscription? Your subscription will remain active until the end of the current billing period.</p>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Reason for cancellation (optional)</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={cancelReason}
                            onChange={(e) => onReasonChange(e.target.value)}
                            placeholder="Please let us know why you're cancelling..."
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Check
                            type="checkbox"
                            id="cancel-confirmation"
                            label="I confirm that I am aware all my data will be deleted at the end of the current subscription period."
                            checked={isConfirmed}
                            onChange={(e) => setIsConfirmed(e.target.checked)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Keep Subscription</Button>
                <Button
                    variant="danger"
                    onClick={onConfirm}
                    disabled={loading || !isConfirmed}
                >
                    {loading ? 'Processing...' : 'Confirm Cancellation'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};