// PersonalTab.js
import React from 'react';
import { ListGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

export const PersonalTab = ({ user_info, profile }) => (
    <>
        <ListGroup variant="flush">
            <ListGroup.Item><strong>Username:</strong> {user_info.username}</ListGroup.Item>
            <ListGroup.Item><strong>Email:</strong> {user_info.email}</ListGroup.Item>
            <ListGroup.Item><strong>Name:</strong> {profile?.first_name} {profile?.last_name}</ListGroup.Item>
            <ListGroup.Item><strong>Date of Birth:</strong> {profile?.date_of_birth}</ListGroup.Item>
            <ListGroup.Item><strong>Phone:</strong> {profile?.phone_number}</ListGroup.Item>
        </ListGroup>
        <Button variant="outline-success" className="mt-3">
            <FontAwesomeIcon icon={faUser} className="me-2" />
            Edit Profile
        </Button>
    </>
);

export default PersonalTab;