// UserAccount.js (Main Component)
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Card, Button, Tab, Tabs, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faShieldAlt, faFileInvoiceDollar, faHdd, faCreditCard, faLock, faSpinner, faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import LoginApiService from '../../services/LoginApiService';
import PersonalTab from './tabs/PersonalTab';
import AccountTab from './tabs/AccountTab';
import SubscriptionTab from './tabs/SubscriptionTab';
import StorageTab from './tabs/StorageTab';
import PaymentTab from './tabs/PaymentTab';
import SecurityTab from './tabs/SecurityTab';
import TabIcon from './components/TabIcon';

const UserAccount = () => {
    const [accountInfo, setAccountInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchAccountInfo();
    }, []);

    const fetchAccountInfo = async () => {
        try {
            const data = await LoginApiService.getUserAccountInfo();
            setAccountInfo(data);
            setLoading(false);
        } catch (err) {
            setError('Failed to load account information');
            setLoading(false);
        }
    };

    if (loading) return (
        <div className="text-center mt-5">
            <FontAwesomeIcon icon={faSpinner} spin size="3x" className="text-success" />
        </div>
    );
    if (error) return <Alert variant="danger" className="mt-5">{error}</Alert>;
    if (!accountInfo) return null;


    const { user_info, profile, subscription, storage, payment_methods, recent_payments } = accountInfo;

    const tabData = [
        { key: 'personal', icon: faUser, title: 'Personal', component: <PersonalTab user_info={user_info} profile={profile} /> },
        { key: 'account', icon: faShieldAlt, title: 'Account', component: <AccountTab user_info={user_info} /> },
        { key: 'subscription', icon: faFileInvoiceDollar, title: 'Subscription', component: <SubscriptionTab subscription={subscription} /> },
        { key: 'storage', icon: faHdd, title: 'Storage', component: <StorageTab storage={storage} subscription={subscription} /> },
        { key: 'payment', icon: faCreditCard, title: 'Payment', component: <PaymentTab payment_methods={payment_methods} recent_payments={recent_payments} /> },
        { key: 'security', icon: faLock, title: 'Security', component: <SecurityTab user_info={user_info} /> },
    ];

    return (
        <div className="bg-white min-vh-100">
            <Container className="py-5">
                <div className="mb-4">
                    <Link to="/journal" className="text-success text-decoration-none">
                        <FontAwesomeIcon icon={faArrowLeftLong} className="me-2" />
                        Back to Journal
                    </Link>
                </div>
                <Card className="shadow-sm" style={{ border: '1px solid rgba(76, 175, 80, 0.3)' }}>
                    <Card.Body>
                        <h3 className="mb-4 text-success">
                            <FontAwesomeIcon icon={faUser} className="me-3" />
                            Your Account
                        </h3>
                        <Tabs defaultActiveKey="personal" className="mb-4">
                            {tabData.map(({ key, icon, title, component }) => (
                                <Tab
                                    key={key}
                                    eventKey={key}
                                    title={
                                        <span className="text-primary text-opacity-75">
                                            <TabIcon icon={icon} />
                                            {title}
                                        </span>
                                    }
                                    tabClassName="text-success"
                                >
                                    {component}
                                </Tab>
                            ))}
                        </Tabs>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
};

export default UserAccount;