import { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faEnvelope, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import AmbientBackground from './AmbientBackground';
import '../assets/css/LoginBackground.css';
import LoginApiService from '../services/LoginApiService';

const RequestPasswordReset = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [focusedField, setFocusedField] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess('');

        try {
            await LoginApiService.requestPasswordReset(email);
            setSuccess('If an account exists for this email, a password reset link has been sent. Please check your email.');
        } catch (error) {
            if (error.response && error.response.status === 429) {
                setError('Too many requests. Please wait a few minutes before trying again.');
            } else {
                setError('An error occurred. Please try again later.');
            }
            console.error('Password reset request error:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Container fluid className="min-vh-100 d-flex align-items-center justify-content-center login-background py-4">
                <AmbientBackground />
                <Row className="justify-content-md-center w-100">
                    <Col xs={12} md={6} lg={4}>
                        <div className="bg-white p-4 rounded shadow-lg login-card" style={{ border: '1px solid rgba(76, 175, 80, 0.3)' }}>
                            <div className="text-center mb-4">
                                <div className="d-flex justify-content-begin">
                                    <Link to="/" className="text-success"><FontAwesomeIcon icon={faArrowLeftLong} className="me-2" /></Link>
                                </div>
                                <Link to="/" style={{ textDecoration: 'none' }}><h2 className="font-weight-bold text-success">TaskPlex<sup>®</sup></h2></Link>
                                <p className="text-muted">Reset your password</p>
                            </div>

                            {error && <Alert variant="danger">{error}</Alert>}
                            {success && <Alert variant="success">{success}</Alert>}

                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3 position-relative" controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onFocus={() => setFocusedField('email')}
                                        onBlur={() => setFocusedField(null)}
                                        disabled={loading}
                                        required
                                        className="border-success"
                                    />
                                    <FontAwesomeIcon
                                        icon={faEnvelope}
                                        className={`form-icon ${focusedField === 'email' ? 'text-success' : 'text-muted'}`}
                                    />
                                </Form.Group>

                                <Button variant="success" type="submit" disabled={loading} className="w-100 py-2 mb-3">
                                    <FontAwesomeIcon icon={faPaperPlane} className="me-2" />
                                    {loading ? 'Sending...' : 'Send Reset Link'}
                                </Button>
                            </Form>

                            <div className="mt-3 text-center">
                                Remember your password? <Link to="/login" className="text-success">Log in</Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default RequestPasswordReset;