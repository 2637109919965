// import React from "react";
import HelpPage from '../components/HelpPage';

const Support = () => {

    return (
        <div>
            <HelpPage />
        </div>
    );
};

export default Support;