// src/hooks/useChatLogic.js
import { useState, useCallback } from 'react';
import aiApi from '../../services/aiApiService';

/**
 * Custom hook for managing chat logic and state
 * @param {Object} options - Configuration options
 * @param {string} options.initialModel - Initial AI model to use
 * @param {number} options.initialTemperature - Initial temperature setting
 * @param {string} options.initialSystemPrompt - Initial system prompt
 * @param {boolean} options.initialUseInternet - Initial internet usage setting
 * @returns {Object} Chat state and handlers
 */
const useChatLogic = ({
    initialModel = 1,
    initialTemperature = 0.1,
    initialSystemPrompt = '',
    initialUseInternet = false
} = {}) => {
    // Core state
    const [conversations, setConversations] = useState([]);
    const [currentChat, setCurrentChat] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [errorType, setErrorType] = useState(null);

    // Settings state
    const [selectedModel, setSelectedModel] = useState(initialModel ? parseInt(initialModel, 1) : null);
    const [temperature, setTemperature] = useState(initialTemperature);
    const [systemPrompt, setSystemPrompt] = useState(initialSystemPrompt);
    const [useInternet, setUseInternet] = useState(initialUseInternet);

    const setModelId = useCallback((modelId) => {
        setSelectedModel(typeof modelId === 'string' ? parseInt(modelId, 1) : modelId);
    }, []);

    /**
     * Clears current error state
     */
    const clearError = useCallback(() => {
        setError(null);
        setErrorType(null);
    }, []);

    /**
     * Creates a new chat conversation
     * @returns {string} ID of the new chat
     */
    const createNewChat = useCallback(() => {
        clearError();
        const newChat = {
            id: Date.now().toString(),
            title: 'New Chat',
            messages: [],
            model: selectedModel,
            settings: {
                temperature,
                systemPrompt,
                useInternet
            },
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString()
        };

        setConversations(prev => [...prev, newChat]);
        setCurrentChat(newChat.id);
        return newChat.id;
    }, [selectedModel, temperature, systemPrompt, useInternet, clearError]);

    /**
     * Sets error state with type classification
     * @param {Error} error - Error object to handle
     */
    const handleError = useCallback((error) => {
        const errorMessage = error.message || 'Failed to send message';
        let type = 'general';

        // Check for specific error messages
        if (errorMessage.includes('Access denied') ||
            errorMessage.includes('subscription') ||
            errorMessage.includes('does not include AI')) {
            type = 'subscription';
        } else if (errorMessage.includes('quota')) {
            type = 'quota';
        }

        setError(errorMessage);
        setErrorType(type);

        // Don't add subscription/quota errors to the chat
        if (type === 'general' && currentChat) {
            setConversations(prev =>
                prev.map(conv =>
                    conv.id === currentChat
                        ? {
                            ...conv,
                            messages: [...conv.messages, {
                                id: Date.now().toString(),
                                content: `Error: ${errorMessage}`,
                                role: 'system',
                                isError: true,
                                timestamp: new Date().toISOString()
                            }]
                        }
                        : conv
                )
            );
        }
    }, [currentChat]);

    /**
     * Updates chat title
     * @param {string} chatId - Chat ID to update
     * @param {string} newTitle - New title for the chat
     */
    const updateChatTitle = useCallback((chatId, newTitle) => {
        if (!chatId || !newTitle.trim()) return;

        setConversations(prev =>
            prev.map(conv =>
                conv.id === chatId
                    ? {
                        ...conv,
                        title: newTitle.trim(),
                        updatedAt: new Date().toISOString()
                    }
                    : conv
            )
        );
    }, []);

    /**
     * Deletes a chat conversation
     * @param {string} chatId - Chat ID to delete
     */
    const deleteChat = useCallback((chatId) => {
        setConversations(prev => prev.filter(conv => conv.id !== chatId));
        if (currentChat === chatId) {
            setCurrentChat(null);
        }
    }, [currentChat]);

    /**
     * Sends a message and gets AI response
     * @param {string} message - User message content
     */
    const sendMessage = useCallback(async (message) => {
        if (!message.trim() || !currentChat) return;

        setLoading(true);
        clearError();

        try {
            // First add the user message
            const userMessage = {
                id: Date.now().toString(),
                content: message,
                role: 'user',
                timestamp: new Date().toISOString()
            };

            setConversations(prev =>
                prev.map(conv =>
                    conv.id === currentChat
                        ? {
                            ...conv,
                            messages: [...conv.messages, userMessage],
                            updatedAt: new Date().toISOString()
                        }
                        : conv
                )
            );

            // Then try to get the AI response
            const currentConversation = conversations.find(c => c.id === currentChat);
            if (!currentConversation) {
                throw new Error('Conversation not found');
            }

            const messages = [
                ...(currentConversation.settings.systemPrompt
                    ? [aiApi.createChatMessage("system", currentConversation.settings.systemPrompt)]
                    : []
                ),
                ...currentConversation.messages.map(msg =>
                    aiApi.createChatMessage(msg.role, msg.content)
                ),
                aiApi.createChatMessage("user", message)
            ];

            const chatRequest = aiApi.createChatRequest(
                messages,
                currentConversation.model,
                currentConversation.settings.temperature
            );

            const response = await aiApi.generateChatResponse(chatRequest);

            const aiMessage = {
                id: Date.now().toString(),
                content: response.message.content,
                role: 'assistant',
                timestamp: new Date().toISOString()
            };

            setConversations(prev =>
                prev.map(conv =>
                    conv.id === currentChat
                        ? {
                            ...conv,
                            messages: [...conv.messages, aiMessage],
                            updatedAt: new Date().toISOString()
                        }
                        : conv
                )
            );

        } catch (err) {
            handleError(err);
        } finally {
            setLoading(false);
        }
    }, [currentChat, conversations, clearError, handleError]);

    /**
     * Updates an existing message
     * @param {string} chatId - Chat ID
     * @param {string} messageId - Message ID
     * @param {string} newContent - New message content
     */
    const updateMessage = useCallback((chatId, messageId, newContent) => {
        if (!chatId || !messageId || !newContent.trim()) return;

        setConversations(prev =>
            prev.map(conv =>
                conv.id === chatId
                    ? {
                        ...conv,
                        messages: conv.messages.map(msg =>
                            msg.id === messageId
                                ? {
                                    ...msg,
                                    content: newContent.trim(),
                                    edited: true,
                                    editedAt: new Date().toISOString()
                                }
                                : msg
                        ),
                        updatedAt: new Date().toISOString()
                    }
                    : conv
            )
        );
    }, []);

    /**
     * Updates chat settings
     * @param {Object} newSettings - New settings object
     */
    const updateSettings = useCallback((newSettings) => {
        if (!currentChat) return;

        setConversations(prev =>
            prev.map(conv =>
                conv.id === currentChat
                    ? {
                        ...conv,
                        settings: { ...conv.settings, ...newSettings },
                        updatedAt: new Date().toISOString()
                    }
                    : conv
            )
        );
    }, [currentChat]);

    return {
        // State
        conversations,
        currentChat,
        loading,
        error,
        errorType,
        selectedModel,
        temperature,
        systemPrompt,
        useInternet,

        // Chat management
        setCurrentChat,
        createNewChat,
        updateChatTitle,
        deleteChat,

        // Message handling
        sendMessage,
        updateMessage,

        // Settings
        setSelectedModel: setModelId,
        setTemperature,
        setSystemPrompt,
        setUseInternet,
        updateSettings,

        // Error handling
        clearError
    };
};

export default useChatLogic;