// src/services/aiApiService.js
import adminApiConfig from '../config/adminApiConfig';

// Define base path constant
const BASE_PATH = '/ai/api/v1/usage';

/**
 * Service for interacting with AI-related endpoints.
 * Handles text completion and chat-based interactions with AI models.
 */
const aiApi = {
    /**
     * Generates a text completion using the specified model.
     * 
     * @param {Object} completionRequest - The request object for text completion
     * @param {string} completionRequest.model_id - ID of the AI model to use
     * @param {string} completionRequest.prompt - The input prompt for completion
     * 
     * @returns {Promise<Object>} Response object containing:
     *   - completion {string} - The generated text completion
     *   - input_tokens {number} - Number of tokens in the input
     *   - output_tokens {number} - Number of tokens in the output
     * 
     * @throws {Error} With formatted message when the API request fails or quota is exceeded
     */
    generateCompletion: async (completionRequest) => {
        try {
            const response = await adminApiConfig.post(`${BASE_PATH}/complete`, completionRequest);
            return response.data;
        } catch (error) {
            console.error('Full error object:', error);
            console.error('Request URL:', error.config?.url);
            console.error('Request method:', error.config?.method);
            console.error('Status:', error.response?.status);

            if (error.response) {
                const status = error.response.status;
                const errorMessage = error.response.data?.detail;

                if (status === 403) {
                    throw new Error(errorMessage || 'Access denied: Please check your subscription status.');
                }

                if (status === 404) {
                    throw new Error(errorMessage || 'Model not found');
                }

                if (status === 400) {
                    throw new Error(errorMessage || 'Invalid request parameters');
                }

                if (status === 500) {
                    throw new Error(errorMessage || 'Server error occurred');
                }
            }

            throw new Error('Failed to communicate with the server. Please try again later.');
        }
    },

    /**
     * Generates a chat response using the specified model.
     * 
     * @param {Object} chatRequest - The request object for chat completion
     * @param {Array<Object>} chatRequest.messages - Array of chat messages
     * @param {string} chatRequest.model_id - ID of the AI model to use
     * @param {number} [chatRequest.temperature=0.7] - Sampling temperature (0.0 to 2.0)
     * @param {number} [chatRequest.max_tokens] - Maximum tokens to generate
     * 
     * @returns {Promise<Object>} Response object containing:
     *   - message {Object} - The generated response message
     *   - input_tokens {number} - Number of tokens in the input
     *   - output_tokens {number} - Number of tokens in the output
     * 
     * @throws {Error} With formatted message when the API request fails, quota is exceeded, or invalid roles are provided
     */
    generateChatResponse: async (chatRequest) => {
        try {
            const response = await adminApiConfig.post(`${BASE_PATH}/chat`, chatRequest);
            return response.data;
        } catch (error) {
            console.error('Error generating chat response:', error);

            if (error.response) {
                const status = error.response.status;
                const errorMessage = error.response.data?.detail;

                if (status === 403) {
                    throw new Error(errorMessage || 'Access denied: Please check your subscription status.');
                }

                if (status === 404) {
                    throw new Error(errorMessage || 'Model not found');
                }

                if (status === 400) {
                    throw new Error(errorMessage || 'Invalid request parameters');
                }

                if (status === 500) {
                    throw new Error(errorMessage || 'Server error occurred');
                }
            }

            throw new Error('Failed to communicate with the server. Please try again later.');
        }
    },

    /**
     * Creates a properly formatted chat message object.
     * 
     * @param {string} role - Role of the message sender.
     *                      Must be one of: "system", "user", "assistant"
     * @param {string} content - Content of the message
     * 
     * @returns {Object} Formatted chat message object
     * 
     * @example
     * const systemMessage = aiApi.createChatMessage("system", "You are a helpful assistant");
     * const userMessage = aiApi.createChatMessage("user", "Hello!");
     */
    createChatMessage: (role, content) => {
        return {
            role,
            content
        };
    },

    /**
    * Creates a properly formatted chat request object.
    * 
    * @param {Array<Object>} messages - Array of chat messages created using createChatMessage()
    * @param {number} modelId - ID of the AI model to use
    * @param {number} [temperature=0.7] - Sampling temperature (0.0 to 2.0)
    * @param {number} [maxTokens=null] - Maximum tokens to generate
    */
    createChatRequest: (messages, modelId, temperature = 0.1, maxTokens = null) => {
        if (typeof modelId !== 'number' || isNaN(modelId)) {
            throw new Error(`Invalid model ID: ${modelId}`);
        }

        return {
            messages,
            model_id: modelId,
            temperature,
            max_tokens: maxTokens
        };
    }
};

export default aiApi;
