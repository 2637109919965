import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ConnectomeLogo from "../assets/images/Connectome.svg";

const Navbar = () => {
  const [isExploreOpen, setIsExploreOpen] = useState(false);

  const toggleExplore = () => setIsExploreOpen(!isExploreOpen);

  // Close dropdown when clicking outside
  const handleClickOutside = (event) => {
    if (!event.target.closest('.dropdown')) {
      setIsExploreOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container-fluid">
        <Link className="navbar-brand ms-3" to="/">
          <img
            src={ConnectomeLogo}
            alt="Connectome Logo"
            height={35}
            className=""
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav me-auto mb-2 mb-lg-0">
            <Link className="nav-link" to="/why-taskplex">
              Why TaskPlex
            </Link>
            <Link className="nav-link" to="/customer-stories">
              Customer stories
            </Link>
            <div className="nav-item dropdown">
              <span
                className="nav-link dropdown-toggle"
                role="button"
                onClick={toggleExplore}
                aria-expanded={isExploreOpen}
              >
                Explore
              </span>
              <ul className={`dropdown-menu${isExploreOpen ? " show" : ""}`}>
                <li><Link className="dropdown-item" to="/tasks">Tasks</Link></li>
                <li><Link className="dropdown-item" to="/calendar">Calendar</Link></li>
                <li><Link className="dropdown-item" to="/note-taking">Note taking</Link></li>
                <li><Link className="dropdown-item" to="/web-clipper">Web Clipper</Link></li>
                <li><Link className="dropdown-item" to="/ai-features">AI features</Link></li>
              </ul>
            </div>
            <Link className="nav-link" to="/plans">
              Plans
            </Link>
            <Link className="nav-link" to="/enterprise">
              Enterprise
            </Link>
          </div>
          <div className="navbar-nav">
            <Link className="nav-link" to="/login">
              Log in
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
