// import React from 'react';
import { Link } from 'react-router-dom'
import { Container, Row, Col, Button, Card, Carousel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faTasks, faStickyNote, faSearch } from '@fortawesome/free-solid-svg-icons';
import ConnectomeSVG from '../assets/images/connectome-large.svg';
import "../assets/css/LandingPage.css"

const LandingPage = () => {
    const videoSnippets = [
        { id: 'daily-planning', title: 'Seamless Daily Planning', src: '/path-to-daily-planning-video.mp4' },
        { id: 'task-management', title: 'Interconnected Task Management', src: '/path-to-task-management-video.mp4' },
        { id: 'note-taking', title: 'Quick Note Capture', src: '/path-to-note-taking-video.mp4' },
        { id: 'search', title: 'Powerful Search and Recall', src: '/path-to-search-video.mp4' },
    ];

    return (
        <div className="landing-page">
            <header className="hero bg-gradient-primary text-white text-center py-5">
                <Container>
                    {/* <Link to="/login" className="login-link position-absolute top-0 end-0 mt-3 me-3 text-white text-decoration-none d-flex align-items-center">
                        <FontAwesomeIcon icon={faUser} className="me-2" />
                        Login
                    </Link> */}
                    {/* <img src={ConnectomeSVG} alt="TaskPlex" className="logo mb-4" style={{ maxWidth: '150px' }} /> */}
                    <h2 className="font-weight-bold text-success logo">TaskPlex<sup>®</sup></h2>
                    <h1 className="display-4 fw-bold mb-3">Organize, Remember, Connect, Thrive</h1>
                    <p className="lead mb-4">TaskPlex: Your all-in-one solution for seamless productivity and perfect recall.</p>
                    <Link to="/plans">
                        <Button variant="light" size="lg" className="pulse-animation">Start Your Free Trial</Button>
                    </Link>
                </Container>
            </header>

            <section className="video-showcase py-5">
                <Container>
                    <h2 className="text-center mb-5">See TaskPlex<sup>®</sup> in Action</h2>
                    <Row className="align-items-center">
                        <Col lg={6} className="mb-4 mb-lg-0">
                            <Carousel fade>
                                {videoSnippets.map((snippet, index) => (
                                    <Carousel.Item key={snippet.id}>
                                        <div className="video-container rounded overflow-hidden">
                                            <video src={snippet.src} autoPlay loop muted className="w-100">
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                        <Carousel.Caption>
                                            <h3>{snippet.title}</h3>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </Col>
                        <Col lg={6}>
                            <h3 className="mb-4">Transform Your Productivity</h3>
                            <ul className="list-unstyled">
                                <li className="mb-3"><FontAwesomeIcon icon={faCalendarCheck} className="me-2 text-primary" /> Never miss an appointment or deadline again</li>
                                <li className="mb-3"><FontAwesomeIcon icon={faTasks} className="me-2 text-primary" /> Connect related tasks and see the big picture</li>
                                <li className="mb-3"><FontAwesomeIcon icon={faStickyNote} className="me-2 text-primary" /> Capture ideas on the go and link them to tasks</li>
                                <li className="mb-3"><FontAwesomeIcon icon={faSearch} className="me-2 text-primary" /> Find anything in seconds, from notes to tasks</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="features py-5 bg-light">
                <Container>
                    <h2 className="text-center mb-5">Solve Real-World Productivity Challenges</h2>
                    <Row>
                        {[
                            { icon: faCalendarCheck, title: "Never Miss a Beat", description: "Sarah, a busy project manager, used to forget important meetings. With TaskPlex, she now starts each day confident and prepared." },
                            { icon: faTasks, title: "Master Complex Projects", description: "Tom, a freelance designer, struggled with juggling multiple clients. TaskPlex helped him interconnect tasks, leading to smoother project completions." },
                            { icon: faStickyNote, title: "Capture Brilliant Ideas", description: "Emma, an aspiring author, often lost great ideas. Now, she quickly jots them in TaskPlex, linking them to her ongoing writing projects." },
                            { icon: faSearch, title: "Find Anything, Anytime", description: "Alex, a researcher, wasted hours searching for old notes. With TaskPlex's powerful search, he now retrieves information in seconds." }
                        ].map((feature, index) => (
                            <Col md={6} key={index} className="mb-4">
                                <Card className="h-100 shadow-hover border-0">
                                    <Card.Body>
                                        <FontAwesomeIcon icon={feature.icon} size="2x" className="text-primary mb-3" />
                                        <Card.Title>{feature.title}</Card.Title>
                                        <Card.Text>{feature.description}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </section>

            <section className="bg-gradient-primary text-white text-center py-5">
                <Container>
                    <h2 className="mb-4">Ready to Transform Your Productivity?</h2>
                    <p className="lead mb-4">Join thousands who have already streamlined their work and life with TaskPlex<sup>®</sup>.</p>
                    <div>
                        <Link to="/plans">
                            <Button variant="light" size="lg" className="pulse-animation">Start Your Free 14-Day Trial</Button>
                        </Link>
                    </div>
                    <p className="mt-3"><small>No credit card required | Unlimited access during trial</small></p>
                </Container>
            </section>
        </div>
    );
};

export default LandingPage;