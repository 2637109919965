// ChangePlanModal.jsx
import React from 'react';
import { Modal, Alert, Button } from 'react-bootstrap';

export const ChangePlanModal = ({
    show,
    onHide,
    error,
    selectedPlan,
    loading,
    onConfirm,
}) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Plan Change</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && <Alert variant="danger">{error}</Alert>}
                <p>Are you sure you want to change to the {selectedPlan?.name} plan?</p>
                <p className="text-muted">Your new plan will be active immediately upon confirmation.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Cancel</Button>
                <Button variant="primary" onClick={onConfirm} disabled={loading}>
                    {loading ? 'Processing...' : 'Confirm Change'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};