// import React from "react";
import RequestPasswordReset from "../components/RequestPasswordReset"
import PublicFooter from "../components/PublicFooter";

const RequestPasswordResetForm = () => {

    return (
        <div>
            <RequestPasswordReset />
            <PublicFooter />
        </div>
    );
};

export default RequestPasswordResetForm;