// src/components/AiChat/ChatNavbar.jsx
import React from 'react';
import { Navbar, Container, Button, Form, Spinner } from 'react-bootstrap';
import { Menu, Globe, Settings } from 'lucide-react';

const ChatNavbar = ({
    isLoading,
    loadError,
    selectedModel,
    availableModels,
    useInternet,
    onModelChange,
    onInternetToggle,
    onSettingsClick,
    onSidebarToggle
}) => {
    return (
        <Navbar bg="light" expand={false} className="border-bottom py-2">
            <Container fluid className="px-2">
                <div className="d-flex align-items-center gap-2 w-100">
                    <Button
                        variant="link"
                        className="p-1"
                        onClick={onSidebarToggle}
                    >
                        <Menu size={20} />
                    </Button>

                    {loadError ? (
                        <span className="text-danger">{loadError}</span>
                    ) : (
                        <Form.Select
                            value={selectedModel || ''}
                            onChange={(e) => onModelChange(Number(e.target.value))}
                            className="flex-grow-1 me-2"
                            style={{ minWidth: '140px', maxWidth: '300px' }}
                            disabled={isLoading}
                        >
                            {availableModels.map(model => (
                                <option key={model.id} value={model.id}>
                                    {model.name}
                                </option>
                            ))}
                        </Form.Select>
                    )}

                    <div className="d-flex align-items-center gap-2">
                        <div className="d-flex align-items-center">
                            <Globe size={20} className="me-2" />
                            <Form.Check
                                type="switch"
                                checked={useInternet}
                                onChange={onInternetToggle}
                                label=""
                            />
                        </div>

                        <Button
                            variant="link"
                            className="p-1"
                            onClick={onSettingsClick}
                        >
                            <Settings size={20} />
                        </Button>
                    </div>
                </div>
            </Container>
        </Navbar>
    );
};

export default ChatNavbar;