import { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { Form, Button, Alert, Container, Row, Col, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignInAlt,
  faEnvelope,
  faLock,
  faArrowLeftLong,
  faWarning,
  faCreditCard,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";
import AmbientBackground from '../components/AmbientBackground';
import '../assets/css/LoginBackground.css';
import PublicFooter from "../components/PublicFooter";

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState({
    isSubscriptionError: false,
    requiresRenewal: false,
    requiresPayment: false,
    isCancelled: false,
    isTrialEnded: false
  });
  const [localLoading, setLocalLoading] = useState(false);
  const [focusedField, setFocusedField] = useState(null);

  const { login, user, loading, error: authError, checkAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const verifyAuth = async () => {
      if (location.state?.fromLogout) {
        return;
      }
      try {
        await checkAuth();
      } catch (err) {
        console.error('Auth check error:', err);
      }
    };

    verifyAuth();
  }, [checkAuth, location.state]);

  useEffect(() => {
    if (user && !loading && !location.state?.fromLogout) {
      navigate('/journal');
    }
  }, [user, loading, navigate, location.state]);

  useEffect(() => {
    if (authError) {
      setError(authError);
    }
  }, [authError]);

  const getSubscriptionAlertContent = () => {
    const { requiresRenewal, requiresPayment, isCancelled, isTrialEnded } = subscriptionStatus;

    let icon = faWarning;
    let actionLink = '/plans';
    let actionText = 'Renew Your subscription.';
    let variant = 'warning';

    if (requiresPayment) {
      icon = faCreditCard;
      actionLink = '/billing';
      actionText = 'Complete Payment';
      variant = 'info';
    } else if (isCancelled) {
      icon = faExclamationTriangle;
      actionText = 'Reactivate Subscription';
    } else if (isTrialEnded) {
      actionText = 'Choose a Plan';
    }

    return {
      icon,
      actionLink,
      actionText,
      variant
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSubscriptionStatus({
      isSubscriptionError: false,
      requiresRenewal: false,
      requiresPayment: false,
      isCancelled: false,
      isTrialEnded: false
    });
    setLocalLoading(true);

    try {
      const response = await login(email, password);
      if (response && response.redirect) {
        window.location.href = response.redirect;
      } else {
        navigate('/journal');
      }
    } catch (err) {
      console.error('Login error:', err);
      if (err.subscriptionStatus) {
        setError(err.response.data.detail);
        setSubscriptionStatus(err.subscriptionStatus);
      } else if (err.message === 'Rate limit exceeded. Please try again later.') {
        setError('Too many login attempts. Please wait a moment before trying again.');
      } else if (err.response && err.response.data) {
        setError(err.response.data.detail || 'Failed to log in. Please check your credentials.');
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setLocalLoading(false);
    }
  };

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" />
      </Container>
    );
  }

  return (
    <div>
      <Container fluid className="min-vh-100 d-flex align-items-center justify-content-center login-background py-4">
        <AmbientBackground />
        <Row className="justify-content-md-center w-100">
          <Col xs={12} md={6} lg={4}>
            <div className="bg-white p-4 rounded shadow-lg login-card" style={{ border: '1px solid rgba(76, 175, 80, 0.3)' }}>
              <div className="text-center mb-4">
                <div className="d-flex justify-content-begin">
                  <Link to="/" className="text-success">
                    <FontAwesomeIcon icon={faArrowLeftLong} className="me-2" />
                  </Link>
                </div>
                <Link to="/" style={{ textDecoration: 'none' }}>
                  <h2 className="font-weight-bold text-success">TaskPlex<sup>®</sup></h2>
                </Link>
                <p className="text-secondary">Organize, Remember, Connect, Thrive</p>
              </div>

              {error && (
                <Alert
                  variant={subscriptionStatus.isSubscriptionError ? getSubscriptionAlertContent().variant : "danger"}
                  className="d-flex align-items-center"
                >
                  {subscriptionStatus.isSubscriptionError ? (
                    <>
                      <FontAwesomeIcon icon={getSubscriptionAlertContent().icon} className="me-2" />
                      <div>
                        {error}
                        <div className="mt-2">
                          <Link
                            to={getSubscriptionAlertContent().actionLink}
                            className="alert-link"
                          >
                            {getSubscriptionAlertContent().actionText}
                          </Link>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div>{error}</div>
                  )}
                </Alert>
              )}

              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3 position-relative text-black" controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onFocus={() => setFocusedField('email')}
                    onBlur={() => setFocusedField(null)}
                    disabled={localLoading}
                    required
                    className="border-success"
                  />
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className={`form-icon ${focusedField === 'email' ? 'text-success' : 'text-muted'}`}
                  />
                </Form.Group>

                <Form.Group className="mb-3 position-relative text-black" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onFocus={() => setFocusedField('password')}
                    onBlur={() => setFocusedField(null)}
                    disabled={localLoading}
                    required
                    className="border-success"
                  />
                  <FontAwesomeIcon
                    icon={faLock}
                    className={`form-icon ${focusedField === 'password' ? 'text-success' : 'text-muted'}`}
                  />
                </Form.Group>

                <Button
                  variant="success"
                  type="submit"
                  disabled={localLoading}
                  className="w-100 py-2 mb-3"
                >
                  {localLoading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                      Logging in...
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faSignInAlt} className="me-2" />
                      Start Organizing
                    </>
                  )}
                </Button>
              </Form>

              <div className="mt-3 text-center">
                <Link to="/request-pw-reset" className="text-success">Forgot Password?</Link>
              </div>
              <div className="mt-2 text-center text-black">
                New to organized, unforgettable life? <br />
                <Link to="/plans" className="text-success">Join now</Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <PublicFooter />
    </div>
  );
};

export default Login;