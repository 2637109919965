// import React from "react";
import ResetPassword from "../components/ResetPassword"
import PublicFooter from "../components/PublicFooter";

const ResetPasswordFrom = () => {

    return (
        <div>
            <ResetPassword />
            <PublicFooter />
        </div>
    );
};

export default ResetPasswordFrom;