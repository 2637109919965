// PlanSelectionModal.jsx
import { useState, useEffect } from 'react';
import { Modal, Form, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import LoginApiService from '../../services/LoginApiService';

const NewUserPlanSelectionModal = ({ show, handleClose, planName, TaskPlexPlanId }) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [focusedField, setFocusedField] = useState(null);
    const [hasValidSubscription, setHasValidSubscription] = useState(false);

    // Clear error and success messages whenever modal is shown
    useEffect(() => {
        if (show) {
            setError('');
            setSuccess('');
            setEmail('');
            setHasValidSubscription(false);
        }
    }, [show]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        setLoading(true);

        try {
            const response = await LoginApiService.createVerification(email, TaskPlexPlanId);
            setSuccess(response.message);
            setEmail('');
            setTimeout(() => handleClose(), 2000);
        } catch (error) {
            const errorMessage = error.response?.data?.detail || 'Verification request failed';
            setError(errorMessage);

            // Check if error message indicates valid subscription
            if (errorMessage.includes("You have a valid subscription.")) {
                setHasValidSubscription(true);
            }
        } finally {
            setLoading(false);
        }
    };

    const renderActionButton = () => {
        if (hasValidSubscription) {
            return (
                <Link to="/login" className="w-100">
                    <Button variant="primary" className="w-100 py-2">
                        Login
                    </Button>
                </Link>
            );
        }

        return (
            <Button variant="success" type="submit" disabled={loading} className="w-100 py-2">
                <FontAwesomeIcon icon={faPaperPlane} className="me-2" />
                {loading ? 'Processing...' : 'Continue with Subscription'}
            </Button>
        );
    };

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Subscribe to {planName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="text-center mb-4">
                    <p className="text-muted">Verify your email to get started</p>
                </div>

                {error && <Alert variant="danger">{error}</Alert>}
                {success && <Alert variant="success">{success}</Alert>}

                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3 position-relative" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onFocus={() => setFocusedField('email')}
                            onBlur={() => setFocusedField(null)}
                            disabled={loading}
                            required
                            className="border-success"
                        />
                        <FontAwesomeIcon
                            icon={faEnvelope}
                            className={`form-icon ${focusedField === 'email' ? 'text-success' : 'text-muted'}`}
                        />
                    </Form.Group>

                    {renderActionButton()}
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default NewUserPlanSelectionModal;