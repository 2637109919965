// src/components/AiChat/LlmChatInterface.jsx
import React, { useState, useEffect, useRef } from 'react';
import {
    Container, Row, Col, Button, Form,
    Nav, Badge, Spinner, Alert,
    Offcanvas, Modal, Navbar
} from 'react-bootstrap';
import {
    MessageSquare, Upload, Globe, Settings,
    Menu, Send
} from 'lucide-react';
import useChatLogic from './useChatLogic';
import aiModelApiService from '../../services/aiModelApiService';
import 'bootstrap/dist/css/bootstrap.min.css';
import ChatNavbar from './ChatNavbar';
import MessageDisplay from './MessageDisplay'; // Message component for displaying individual chat messages
import SettingsModal from './SettingsModal';

// Tag badge component for consistent tag display
const TagBadge = ({ tag, onRemove, isFilter = false }) => (
    <Badge
        bg="primary"
        className="me-1 mb-1"
        style={{ cursor: isFilter ? 'pointer' : 'default' }}
    >
        {tag}
        {onRemove && (
            <Button
                variant="link"
                size="sm"
                className="text-white p-0 ms-1"
                onClick={() => onRemove(tag)}
            >
                ×
            </Button>
        )}
    </Badge>
);


const ChatInterface = () => {
    // Refs
    const messagesEndRef = useRef(null);
    const fileInputRef = useRef(null);

    // State
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [showSettings, setShowSettings] = useState(false);
    const [tags, setTags] = useState(['Work', 'Personal', 'Research', 'Creative']);
    const [selectedTags, setSelectedTags] = useState([]);
    const [providers, setProviders] = useState([]);
    const [availableModels, setAvailableModels] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loadError, setLoadError] = useState(null);

    // Initialize chat logic
    const chat = useChatLogic({
        initialModel: '',
        initialTemperature: 0.1
    });

    // Scroll to bottom of messages
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [chat.conversations]);

    // Fetch providers and models
    useEffect(() => {
        const fetchProvidersAndModels = async () => {
            try {
                setIsLoading(true);
                const providersData = await aiModelApiService.listProviders({
                    activeOnly: true,
                    includeModels: true
                });

                setProviders(providersData);

                const models = providersData.flatMap(provider =>
                    provider.models.map(model => ({
                        id: model.id,
                        name: `${provider.name} - ${model.name}`,
                        providerId: provider.id
                    }))
                );

                setAvailableModels(models);

                if (models.length > 0 && !chat.selectedModel) {
                    chat.setSelectedModel(models[0].id);
                }

                setLoadError(null);
            } catch (error) {
                console.error('Error fetching providers and models:', error);
                setLoadError('Failed to load available AI models. Please try again later.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchProvidersAndModels();
    }, []);

    // Message handling
    const handleSendMessage = async () => {
        if (!message.trim()) return;
        await chat.sendMessage(message);
        setMessage('');
    };

    const handleFileUpload = (e) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const message = `Uploaded file: ${file.name}`;
                chat.sendMessage(message, { attachment: e.target?.result });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleCopy = async (content) => {
        try {
            await navigator.clipboard.writeText(content);
            // Optional: Show a toast or notification
        } catch (err) {
            console.error('Failed to copy text:', err);
        }
    };

    const handleEdit = (chatId, messageId, newContent, isEditing) => {
        chat.updateMessage(chatId, messageId, newContent, isEditing);
    };

    // Settings management
    useEffect(() => {
        if (chat.currentChat) {
            chat.updateSettings({
                temperature: chat.temperature,
                systemPrompt: chat.systemPrompt,
                useInternet: chat.useInternet
            });
        }
    }, [chat.temperature, chat.systemPrompt, chat.useInternet, chat.currentChat]);

    return (
        <div className="min-vh-100 d-flex flex-column overflow-hidden position-relative">
            {isLoading ? (
                <div className="d-flex align-items-center justify-content-center min-vh-100">
                    <div className="text-center">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                        <p className="mt-2 text-muted">Loading chat interface...</p>
                    </div>
                </div>
            ) : (
                <>
                    <ChatNavbar
                        isLoading={isLoading}
                        loadError={loadError}
                        selectedModel={chat.selectedModel}
                        availableModels={availableModels}
                        useInternet={chat.useInternet}
                        onModelChange={chat.setSelectedModel}
                        onInternetToggle={() => chat.setUseInternet(!chat.useInternet)}
                        onSettingsClick={() => setShowSettings(true)}
                        onSidebarToggle={() => setIsSidebarOpen(!isSidebarOpen)}
                    />

                    {/* Main content area */}
                    <Row className="flex-grow-1 g-0 overflow-hidden">
                        {/* Sidebar */}
                        <Offcanvas
                            show={isSidebarOpen}
                            onHide={() => setIsSidebarOpen(false)}
                            placement="start"
                            className="w-100 w-sm-auto"
                            style={{ maxWidth: '280px' }}
                        >
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>Conversations</Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Button
                                    variant="primary"
                                    className="w-100 mb-3"
                                    onClick={chat.createNewChat}
                                >
                                    New Chat
                                </Button>

                                <div className="mb-3">
                                    <small className="text-muted">Tags</small>
                                    <div className="mt-2">
                                        {tags.map(tag => (
                                            <TagBadge
                                                key={tag}
                                                tag={tag}
                                                isFilter={true}
                                                onRemove={null}
                                            />
                                        ))}
                                    </div>
                                </div>

                                <Nav className="flex-column">
                                    {chat.conversations.map(conv => (
                                        <Nav.Item key={conv.id}>
                                            <Nav.Link
                                                active={chat.currentChat === conv.id}
                                                onClick={() => chat.setCurrentChat(conv.id)}
                                                className="d-flex align-items-center py-2"
                                            >
                                                <MessageSquare size={16} className="me-2" />
                                                {conv.title || 'New Chat'}
                                            </Nav.Link>
                                        </Nav.Item>
                                    ))}
                                </Nav>
                            </Offcanvas.Body>
                        </Offcanvas>

                        {/* Chat area */}
                        <Col className="d-flex flex-column h-100 overflow-hidden">
                            {chat.error && (
                                <Alert
                                    variant="danger"
                                    onClose={() => chat.setError(null)}
                                    dismissible
                                    className="m-2"
                                >
                                    {chat.error}
                                </Alert>
                            )}

                            {/* Messages area */}
                            <div
                                className="flex-grow-1 overflow-y-auto px-2 px-sm-3 py-2"
                                style={{
                                    paddingBottom: 'env(safe-area-inset-bottom)',
                                    WebkitOverflowScrolling: 'touch'
                                }}
                            >
                                {chat.currentChat && chat.conversations
                                    .find(conv => conv.id === chat.currentChat)
                                    ?.messages.map(msg => (
                                        <MessageDisplay
                                            key={msg.id}
                                            message={msg}
                                            chatId={chat.currentChat}
                                            onEdit={handleEdit}
                                            onCopy={handleCopy}
                                        />
                                    ))}
                                {chat.loading && (
                                    <div className="text-center py-3">
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </div>
                                )}
                                <div ref={messagesEndRef} />
                            </div>

                            {/* Input area */}
                            <div
                                className="border-top bg-white"
                                style={{
                                    paddingBottom: 'env(safe-area-inset-bottom)',
                                    position: 'sticky',
                                    bottom: 0
                                }}
                            >
                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        handleSendMessage();
                                    }}
                                    className="p-2 p-sm-3"
                                >
                                    <div className="d-flex gap-2">
                                        <Form.Group controlId="file-upload">
                                            <Form.Label className="mb-0">
                                                <Button
                                                    variant="outline-secondary"
                                                    className="d-flex align-items-center p-2"
                                                >
                                                    <Upload size={20} />
                                                </Button>
                                            </Form.Label>
                                            <Form.Control
                                                type="file"
                                                onChange={handleFileUpload}
                                                className="d-none"
                                                ref={fileInputRef}
                                            />
                                        </Form.Group>

                                        <Form.Control
                                            as="textarea"
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            placeholder="Type your message..."
                                            rows={1}
                                            style={{
                                                resize: 'none',
                                                maxHeight: '120px',
                                                WebkitAppearance: 'none',
                                                borderRadius: '4px'
                                            }}
                                            className="py-2"
                                        />

                                        <Button
                                            variant="primary"
                                            onClick={handleSendMessage}
                                            disabled={chat.loading || !message.trim()}
                                            className="px-3"
                                        >
                                            {chat.loading ? (
                                                <Spinner animation="border" size="sm" />
                                            ) : (
                                                <Send size={20} />
                                            )}
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                    {/* Settings Modal */}
                    <SettingsModal
                        show={showSettings}
                        onHide={() => setShowSettings(false)}
                        temperature={chat.temperature}
                        systemPrompt={chat.systemPrompt}
                        useInternet={chat.useInternet}
                        onTemperatureChange={chat.setTemperature}
                        onSystemPromptChange={chat.setSystemPrompt}
                        onInternetToggle={() => chat.setUseInternet(!chat.useInternet)}
                    />
                </>
            )}
        </div>
    );
}


export default ChatInterface;