// components/AmbientBackground.jsx
import React, { useMemo } from 'react';

const AmbientBackground = () => {
    const shapes = useMemo(() => {
        const shapesArray = [];
        for (let i = 0; i < 5; i++) {
            const style = {
                left: `${Math.random() * 100}%`,
                top: `${Math.random() * 100}%`,
                width: `${Math.random() * 100 + 50}px`,
                height: `${Math.random() * 100 + 50}px`,
                animationDelay: `${Math.random() * 20}s`,
            };
            shapesArray.push(
                <div
                    key={i}
                    className="ambient-shape"
                    style={style}
                    aria-hidden="true"
                />
            );
        }
        return shapesArray;
    }, []);

    return <div className="ambient-shapes">{shapes}</div>;
};

export default AmbientBackground;