// import React from "react";
import Navbar from "../components/Navbar"
import LandingPage from "../components/LandingPage";
import PublicFooter from "../components/PublicFooter";

const PublicLandingPage = () => {

    return (
        <div>
            <Navbar />
            <LandingPage />
            <PublicFooter />
        </div>
    );
};

export default PublicLandingPage;