// NewUserSubscriptionPlans.jsx
import { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PricingTier from './PricingTier';
import PricingHeader from './PricingHeader';
import AmbientBackground from '../AmbientBackground';
import NewUserPlanSelectionModal from './NewUserPlanSelectionModal';
import { PRICING_DATA } from './PricingData';
import '../../assets/css/LoginBackground.css';

const NewUserSubscriptionPlans = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState({ id: null, name: '' });

    const handlePlanSelect = (planId, planName) => {
        setSelectedPlan({ id: planId, name: planName });
        setShowModal(true);
    };

    return (
        <div>
            <Container fluid className="min-vh-100 d-flex align-items-center justify-content-center login-background py-4">
                <AmbientBackground />
                <Row className="justify-content-md-center w-100">
                    <Col xs={12} md={10} lg={8}>
                        <div className="bg-white p-4 rounded shadow-lg login-card" style={{ border: '1px solid rgba(76, 175, 80, 0.3)' }}>
                            <PricingHeader />
                            <Row>
                                {PRICING_DATA.map((tier, index) => (
                                    <Col key={index} md={4} className="mb-4">
                                        <PricingTier
                                            {...tier}
                                            onSelectPlan={handlePlanSelect}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>

            <NewUserPlanSelectionModal
                show={showModal}
                handleClose={() => setShowModal(false)}
                planName={selectedPlan.name}
                TaskPlexPlanId={selectedPlan.id}
            />
        </div>
    );
};

export default NewUserSubscriptionPlans;