// components/PricingTier.jsx
// import React from 'react';
import { Card, Button } from 'react-bootstrap';
import PricingFeature from './PricingFeature';

const PricingTier = ({ name, price, features, isPopular, isPerSeat, planId, onSelectPlan }) => (
    <Card className={`h-100 ${isPopular ? 'border-success' : ''}`}>
        <Card.Header as="h5" className="text-center">
            {name}
            {isPopular && <span className="badge bg-success ms-2">Most Popular</span>}
        </Card.Header>
        <Card.Body>
            <Card.Title className="text-center mb-4">
                <span className="h2">${price}</span>
                <small className="text-muted">/{isPerSeat ? 'seat/month' : 'month'}</small>
            </Card.Title>
            <ul className="list-unstyled">
                {features.map((feature, index) => (
                    <PricingFeature key={index} feature={feature} />
                ))}
            </ul>
        </Card.Body>
        <Card.Footer>
            <Button
                variant={isPopular ? "success" : "outline-success"}
                className="w-100"
                onClick={() => onSelectPlan(planId, name)}
            >
                Choose Plan
            </Button>
        </Card.Footer>
    </Card>
);

export default PricingTier;
