// src/components/AiChat/SettingsModal.jsx
import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const SettingsModal = ({
    show,
    onHide,
    temperature,
    systemPrompt,
    useInternet,
    onTemperatureChange,
    onSystemPromptChange,
    onInternetToggle
}) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            fullscreen="sm-down"
        >
            <Modal.Header closeButton>
                <Modal.Title>Chat Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-4">
                        <Form.Label className="d-flex justify-content-between">
                            Temperature: <span>{temperature.toFixed(1)}</span>
                        </Form.Label>
                        <Form.Range
                            value={temperature}
                            onChange={(e) => onTemperatureChange(parseFloat(e.target.value))}
                            min="0"
                            max="1"
                            step="0.1"
                        />
                        <small className="text-muted d-block mt-2">
                            Lower values make responses more focused and deterministic,
                            higher values make them more creative and varied.
                        </small>
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Label>System Prompt</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            value={systemPrompt}
                            onChange={(e) => onSystemPromptChange(e.target.value)}
                            placeholder="Enter system instructions for the AI..."
                        />
                        <small className="text-muted d-block mt-2">
                            Define the AI's behavior and context for this conversation.
                        </small>
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Label className="mb-0">Internet Access</Form.Label>
                        <div className="d-flex align-items-center mt-2">
                            <Form.Check
                                type="switch"
                                checked={useInternet}
                                onChange={onInternetToggle}
                                label=""
                                className="me-2"
                            />
                            <span>Allow AI to access the internet for up-to-date information</span>
                        </div>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SettingsModal;