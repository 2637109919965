// components/PricingHeader.jsx
// import React from 'react';
import { Link } from 'react-router-dom';

const PricingHeader = () => (
    <div className="text-center mb-4">
        <Link to="/" style={{ textDecoration: 'none' }}>
            <h2 className="font-weight-bold text-success">TaskPlex<sup>®</sup></h2>
        </Link>
        <p className="text-secondary">Choose the Right Plan for Your Needs</p>
    </div>
);

export default PricingHeader;