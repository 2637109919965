
// import { Navigate } from "react-router-dom";
// import { useAuth } from "../contexts/AuthContext";
// import { useEffect } from "react";

// const ProtectedRoute = ({ children }) => {
//   const { user, loading, checkAuth } = useAuth();


//   useEffect(() => {
//     checkAuth(); // Ensure authentication check happens on component mount
//   }, [checkAuth]);

//   // If loading, display loading UI and don't redirect until done
//   if (loading) {
//     return <div>Loading...</div>; // Or a spinner, or some visual cue
//   }

//   // Wait until loading is done to make any routing decisions
//   if (!user) {
//     return <Navigate to="/login" replace />;
//   }

//   // Once loading is done, handle the admin redirect
//   if (user.is_admin) {
//     return <Navigate to="/admin" replace />;
//   }


//   // If user is authenticated and not admin, allow access
//   return children;
// };

// export default ProtectedRoute;


import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { Spinner } from "react-bootstrap";

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (user.username == 'admin') {
    return <Navigate to="/admin" replace />;
  }

  return children;
};

export default ProtectedRoute;
