// import React from "react";
import Navbar from '../components/Navbar';
import NewUserSubscriptionPlans from '../components/SubscriptionPlans/NewUserSubscriptionPlans';
import PublicFooter from "../components/PublicFooter";

const Plans = () => {

    return (
        <div>
            <Navbar />
            <NewUserSubscriptionPlans />
            <PublicFooter />
        </div>
    );
};

export default Plans;