// src/components/AiChat/MessageDisplay.jsx
import React, { useState } from 'react';
import { Card, Button, Form } from 'react-bootstrap';
import { Edit2, Copy } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const MessageDisplay = ({ message, chatId, onEdit, onCopy }) => {
    const isEditing = message.isEditing;
    const [editContent, setEditContent] = useState(message.content);

    const handleSaveEdit = () => {
        onEdit(chatId, message.id, editContent);
    };

    return (
        <Card
            className={`mb-3 ${message.role === 'user' ? 'ms-auto' : 'me-auto'}`}
            style={{ maxWidth: '85%' }}
        >
            <Card.Body
                className={message.role === 'user' ? 'bg-primary text-white' : 'bg-light'}
                style={{ borderRadius: '0.375rem' }}
            >
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <small className="opacity-75">
                        {message.role === 'user' ? 'You' : 'AI'}
                        {message.edited && ' (edited)'}
                    </small>
                    <div>
                        {message.role === 'user' && !isEditing && (
                            <Button
                                variant="link"
                                size="sm"
                                className="text-white p-0 me-2"
                                onClick={() => onEdit(chatId, message.id, null, true)}
                            >
                                <Edit2 size={14} />
                            </Button>
                        )}
                        <Button
                            variant="link"
                            size="sm"
                            className={`${message.role === 'user' ? 'text-white' : 'text-dark'} p-0`}
                            onClick={() => onCopy(message.content)}
                        >
                            <Copy size={14} />
                        </Button>
                    </div>
                </div>

                {isEditing ? (
                    <Form onSubmit={(e) => { e.preventDefault(); handleSaveEdit(); }}>
                        <Form.Control
                            as="textarea"
                            value={editContent}
                            onChange={(e) => setEditContent(e.target.value)}
                            rows={3}
                            className="mb-2"
                            autoFocus
                        />
                        <div className="d-flex justify-content-end gap-2">
                            <Button
                                variant="outline-light"
                                size="sm"
                                onClick={() => onEdit(chatId, message.id, null, false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="light"
                                size="sm"
                                onClick={handleSaveEdit}
                            >
                                Save
                            </Button>
                        </div>
                    </Form>
                ) : (
                    <ReactMarkdown
                        components={{
                            code({ node, inline, className, children, ...props }) {
                                const match = /language-(\w+)/.exec(className || '');
                                return !inline && match ? (
                                    <SyntaxHighlighter
                                        style={oneDark}
                                        language={match[1]}
                                        PreTag="div"
                                        {...props}
                                    >
                                        {String(children).replace(/\n$/, '')}
                                    </SyntaxHighlighter>
                                ) : (
                                    <code className={className} {...props}>
                                        {children}
                                    </code>
                                );
                            }
                        }}
                        className={message.role === 'user' ? 'text-white' : 'text-dark'}
                    >
                        {message.content}
                    </ReactMarkdown>
                )}

                {message.attachment && (
                    <img
                        src={message.attachment}
                        alt="Uploaded content"
                        className="img-fluid rounded mt-2"
                        style={{ maxHeight: '300px' }}
                    />
                )}
            </Card.Body>
            <Card.Footer className="text-muted py-1">
                <small>{new Date(message.timestamp).toLocaleTimeString()}</small>
            </Card.Footer>
        </Card>
    );
};

export default MessageDisplay;