import { useEffect, useState } from 'react';
import { Container, Row, Col, Alert, Spinner, Stack } from 'react-bootstrap';
import Navbar from '../components/Navbar';
import PublicFooter from "../components/PublicFooter";
import PayPalSubscriptionButtonAccount from '../components/UserAccountInfo/components/PayPalSubscriptionButtonAccount';
import LoginApiService from '../services/LoginApiService';
import { useAuth } from '../contexts/AuthContext';

const PaymentAccount = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { user } = useAuth();
    const [agreementAccepted, setAgreementAccepted] = useState(false);
    const [agreementContent, setAgreementContent] = useState({
        content_url: '',
        latest_version: '',
        agreement_type: 'terms_of_service'
    });
    const [isAgreementLoading, setIsAgreementLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        // Check if required data is available in the context
        if (user?.TaskPlexPlanId && user?.paypalPlanId && user?.planName) {
            setIsLoading(false);
        } else {
            setError('Required plan information is missing');
            setIsLoading(false);
        }
    }, [user]);

    useEffect(() => {
        const checkAgreementStatus = async () => {
            try {
                console.log("Next: checkStatus");
                const status = await LoginApiService.checkStatus("terms_of_service");

                if (status) {
                    setAgreementAccepted(status.has_accepted);
                    setAgreementContent({
                        content_url: status.content_url,
                        latest_version: status.latest_version,
                        agreement_type: 'terms_of_service'
                    });
                } else {
                    console.error('No status returned from API');
                }
            } catch (error) {
                console.error('Error checking agreement status:', error);
                if (error.response) {
                    console.error('Error response:', error.response);
                    console.error('Error response data:', error.response.data);
                }
                setError('Failed to load agreement status. Please try again.');
            } finally {
                setIsAgreementLoading(false);
            }
        };

        if (user?.email) {
            checkAgreementStatus();
        }
    }, [user]);

    const handleAcceptAgreement = async () => {
        try {
            const response = await LoginApiService.acceptAgreement(
                agreementContent.agreement_type,
                agreementContent.latest_version
            );

            if (response && response.agreement_id) {
                setAgreementAccepted(true);
                setError('');
            } else {
                setError('Failed to accept agreement. Please try again.');
            }
        } catch (error) {
            console.error('Error accepting agreement:', error);
            setError('Failed to accept agreement. Please try again.');
        }
    };

    if (!user?.email || isLoading || isAgreementLoading) {
        return (
            <>
                <Navbar />
                <Container className="mt-5 text-center vh-100 d-flex flex-column justify-content-center">
                    <Row className="justify-content-center">
                        <Col xs={12} className="text-center">
                            <Spinner animation="border" role="status" className="mb-3">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                            <p className="text-muted">Loading payment information...</p>
                        </Col>
                    </Row>
                </Container>
                <PublicFooter />
            </>
        );
    }

    return (
        <>
            <Navbar />
            <Container className="flex-grow-1 py-5 vh-100">
                <Row className="justify-content-center">
                    <Col xs={12} sm={10} md={8} lg={6}>
                        <Stack gap={4}>
                            {error && (
                                <Alert variant="danger" onClose={() => setError('')} dismissible>
                                    {error}
                                </Alert>
                            )}

                            <div className="d-flex justify-content-center">
                                <PayPalSubscriptionButtonAccount
                                    TaskPlexPlanId={user.TaskPlexPlanId}
                                    PayPalPlanId={user.paypalPlanId}
                                    planName={user.planName}
                                    email={user.email}
                                    disabled={!agreementAccepted}
                                    agreementAccepted={agreementAccepted}
                                    onAcceptAgreement={handleAcceptAgreement}
                                    agreementContent={agreementContent}
                                />
                            </div>
                        </Stack>
                    </Col>
                </Row>
            </Container>

            <PublicFooter />
        </>
    );
};

export default PaymentAccount;