// constants/PricingData.jsx
export const PRICING_DATA = [
    {
        planId: '1',
        name: 'Free Trial',
        price: 0,
        features: ['14 days free', 'Up to 5 projects', '100 tasks per project', 'Basic reporting'],
        isPopular: false,
        isPerSeat: false,
    },
    {
        planId: '100001',
        name: 'Basic',
        price: 4.99,
        features: ['Unlimited projects', 'Unlimited tasks', 'Advanced reporting', 'Team collaboration'],
        isPopular: false,
        isPerSeat: false,
    },
    {
        planId: '100005',
        name: 'Pro',
        price: 19.99,
        features: ['Everything in Pro', 'Priority support', 'Custom integrations', 'Advanced security'],
        isPopular: true,
        isPerSeat: false,
    },
    {
        planId: '100006',
        name: 'Advanced',
        price: 29.99,
        features: ['Everything in Pro', 'Priority support', 'Custom integrations', 'Advanced security'],
        isPopular: false,
        isPerSeat: false,
    },
    {
        planId: '100007',
        name: 'Enterprise',
        price: 9.99,
        features: ['Everything in Pro', 'Priority support', 'Custom integrations', 'Advanced security'],
        isPopular: false,
        isPerSeat: true,
    },
];