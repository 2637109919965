// PricingTier.jsx
import React from 'react';
import { ListGroup, Button, Badge } from 'react-bootstrap';

export const PricingTier = ({
    name,
    price,
    features,
    isPopular,
    onSelectPlan,
    planId,
    isPerSeat,
    activeSubscriptionId
}) => {
    // Convert values to numbers for consistent comparison
    const numericPlanId = Number(planId);
    const numericActiveSubscriptionId = Number(activeSubscriptionId);

    // Check if current plan is active
    const isActive = numericPlanId === numericActiveSubscriptionId;

    // Free Trial should be disabled if there's any active subscription
    const isFreeTrialDisabled = numericPlanId === 1 && numericActiveSubscriptionId > 0;

    const getButtonText = () => {
        if (isActive) {
            return 'Current Plan';
        }
        if (isFreeTrialDisabled) {
            return 'Not Available';
        }
        // If no active subscription, show "Select Plan" instead of "Upgrade"
        if (!activeSubscriptionId) {
            return 'Select Plan';
        }
        // For paid plans, show "Upgrade" text
        return `Upgrade to ${name}`;
    };

    const formatPrice = () => {
        if (price === 0) {
            return 'Free';
        }

        const formattedPrice = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2
        }).format(price);

        return `${formattedPrice}${isPerSeat ? '/seat' : '/month'}`;
    };

    return (
        <div className={`bg-white p-4 rounded h-100 position-relative ${isPopular ? 'border border-success' : 'border'}`}>
            {/* Wrapper div with flex column layout */}
            <div className="d-flex flex-column h-100">
                {isPopular && (
                    <div className="position-absolute top-0 start-50 translate-middle">
                        <Badge bg="success" className="px-3 py-2">Most Popular</Badge>
                    </div>
                )}
                {isActive && (
                    <div className="position-absolute top-0 start-50 translate-middle">
                        <Badge bg="primary" className="px-3 py-2">Current</Badge>
                    </div>
                )}
                <h3 className="text-center mb-3">{name}</h3>
                <div className="text-center mb-4">
                    <span className="h2">{formatPrice()}</span>
                </div>
                {/* Add flex-grow-1 to push button to bottom */}
                <div className="flex-grow-1">
                    <ListGroup variant="flush" className="mb-4">
                        {features.map((feature, index) => (
                            <ListGroup.Item key={index} className="border-0 py-2">
                                ✓ {feature}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </div>
                <div className="d-grid mt-auto">
                    <Button
                        variant={isPopular ? "success" : "outline-primary"}
                        onClick={() => onSelectPlan(numericPlanId, name)}
                        disabled={isActive || isFreeTrialDisabled}
                        title={isFreeTrialDisabled ? "Free trial is only available for new users" : ""}
                    >
                        {getButtonText()}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default PricingTier;