// PaymentTab.js
import React from 'react';
import { Row, Col, ListGroup, Button, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';

export const PaymentTab = ({ payment_methods, recent_payments }) => (
    <Row>
        <Col md={6}>
            <h5 className="mb-3 text-success">Payment Methods</h5>
            {payment_methods && payment_methods.length > 0 ? (
                <ListGroup variant="flush">
                    {payment_methods.map((method, index) => (
                        <ListGroup.Item key={index}>
                            <FontAwesomeIcon icon={faCreditCard} className="me-2 text-success" />
                            {method.method_type} {method.is_default && <Badge bg="success">Default</Badge>}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            ) : (
                <p className="text-secondary">No payment methods added</p>
            )}
            <Button variant="outline-success" className="mt-3">
                <FontAwesomeIcon icon={faCreditCard} className="me-2" />
                Add Payment Method
            </Button>
        </Col>
        <Col md={6}>
            <h5 className="mb-3 text-success">Recent Payments</h5>
            {recent_payments && recent_payments.length > 0 ? (
                <ListGroup variant="flush">
                    {recent_payments.map((payment, index) => (
                        <ListGroup.Item key={index}>
                            <FontAwesomeIcon icon={faFileInvoiceDollar} className="me-2 text-success" />
                            {new Date(payment.payment_date).toLocaleDateString()} - ${payment.amount}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            ) : (
                <p className="text-secondary">No recent payments</p>
            )}
            <Button variant="outline-success" className="mt-3">
                <FontAwesomeIcon icon={faFileInvoiceDollar} className="me-2" />
                View All Payments
            </Button>
        </Col>
    </Row>
);

export default PaymentTab;