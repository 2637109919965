// src/services/storageApi.js

import axios from 'axios';
import adminApiConfig from '../config/adminApiConfig';

const storageApi = axios.create(adminApiConfig);

/**
 * Get available storage subscription plans with their current prices.
 * @returns {Promise<Array<Object>>} List of storage subscription plans.
 * @property {number} id - The ID of the subscription plan.
 * @property {string} name - The name of the plan.
 * @property {string} description - Description of what the plan offers.
 * @property {string} billing_cycle - The billing cycle (e.g., 'monthly').
 * @property {string} features - Additional features included in the plan.
 * @property {number} base_storage_gb - Amount of storage in gigabytes.
 * @property {number} trial_period_days - Number of trial days (if applicable).
 * @property {boolean} is_trial_only - Whether the plan is trial-only.
 * @property {string} paypal_plan_id - PayPal plan identifier.
 * @property {string} paypal_product_id - PayPal product identifier.
 * @property {number|null} current_price - Current price of the plan, null if no price is set.
 * @throws {Error} If there's an issue fetching the storage plans.
 * @throws {Error} If the user is not authenticated.
 */
export const getStoragePlans = async () => {
    try {
        const response = await storageApi.get('/api/storage/storage-plans');
        return response.data;
    } catch (error) {
        console.error('Error fetching storage plans:', error);
        throw error;
    }
};

/**
 * Fetches a user's active storage subscription details.
 * @param {number} userId - The ID of the user whose storage subscription to retrieve.
 * @returns {Promise<Object>} Storage subscription details.
 * @property {string} plan_name - Name of the storage plan.
 * @property {number} storage_gb - Amount of storage in gigabytes.
 * @property {Date} start_date - Start date of the subscription.
 * @property {Date} end_date - End date of the subscription.
 * @property {boolean} auto_renew - Whether the subscription auto-renews.
 * @throws {Error} If no active storage subscription is found (404).
 * @throws {Error} If there's an issue fetching the subscription (500).
 * @throws {Error} If the user is not authenticated.
 */
export const getStorageSubscription = async (userId) => {
    try {
        const response = await storageApi.get(`/api/storage/users/${userId}/subscription`);

        // Convert string dates to Date objects for easier handling in the frontend
        const subscription = {
            ...response.data,
            start_date: new Date(response.data.start_date),
            end_date: new Date(response.data.end_date)
        };

        return subscription;
    } catch (error) {
        console.error('Error fetching storage subscription:', error);

        // Enhance error handling with specific error types
        if (error.response?.status === 404) {
            throw new Error('No active storage subscription found for this user');
        }

        throw error;
    }
};
