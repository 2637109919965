// SecurityTab.js
import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faShieldAlt } from '@fortawesome/free-solid-svg-icons';

export const SecurityTab = ({ user_info }) => (
    <>
        <p className="mb-3 text-secondary">Manage your account security settings:</p>
        <Button variant="outline-success" className="me-3">
            <FontAwesomeIcon icon={faLock} className="me-2" />
            Change Password
        </Button>
        <Button variant="outline-secondary">
            <FontAwesomeIcon icon={faShieldAlt} className="me-2" />
            {user_info.two_factor_enabled ? 'Disable' : 'Enable'} Two-Factor Authentication
        </Button>
    </>
);

export default SecurityTab;