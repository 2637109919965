// import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Link } from "react-router-dom";
import { Container, Row, Col, Table, Card, Accordion } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import AppFooter from "./AppFooter";

const Section = ({ title, children }) => (
    <div className="mb-5">
        <h2 className="display-6 mb-4">{title}</h2>
        {children}
    </div>
);

const ShortcutTable = ({ shortcuts }) => (
    <div className="table-responsive">
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>Command</th>
                    <th>Windows/Linux</th>
                    <th>macOS</th>
                </tr>
            </thead>
            <tbody>
                {shortcuts.map((shortcut, index) => (
                    <tr key={index}>
                        <td>{shortcut.command}</td>
                        <td>{shortcut.windows}</td>
                        <td>{shortcut.mac}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    </div>
);

const KeyboardShortcutsAccordion = ({ shortcuts }) => (
    <Accordion>
        {Object.entries(shortcuts).map(([category, shortcutList], index) => (
            <Accordion.Item eventKey={index.toString()} key={index}>
                <Accordion.Header>{category.charAt(0).toUpperCase() + category.slice(1)}</Accordion.Header>
                <Accordion.Body>
                    <ShortcutTable shortcuts={shortcutList} />
                </Accordion.Body>
            </Accordion.Item>
        ))}
    </Accordion>
);

const FAQ = ({ items }) => (
    <Accordion>
        {items.map((item, index) => (
            <Accordion.Item eventKey={index.toString()} key={index}>
                <Accordion.Header>{item.question}</Accordion.Header>
                <Accordion.Body>{item.answer}</Accordion.Body>
            </Accordion.Item>
        ))}
    </Accordion>
);

const VideoTutorial = ({ title, description }) => (
    <Card>
        <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Text>{description}</Card.Text>
            <div className="bg-light text-center py-5">
                <span className="text-muted">Video Placeholder</span>
            </div>
        </Card.Body>
    </Card>
);

const HelpPage = () => {

    const { user } = useAuth();

    const shortcuts = {
        essentials: [
            { command: "Copy", windows: "Control + C", mac: "Cmd + C" },
            { command: "Cut", windows: "Control + X", mac: "Cmd + X" },
            { command: "Paste", windows: "Control + V", mac: "Cmd + V" },
            { command: "Paste without formatting", windows: "Control + Shift + V", mac: "Cmd + Shift + V" },
            { command: "Undo", windows: "Control + Z", mac: "Cmd + Z" },
            { command: "Redo", windows: "Control + Shift + Z", mac: "Cmd + Shift + Z" },
            { command: "Add a line break", windows: "Shift + Enter / Control + Enter", mac: "Shift + Enter / Cmd + Enter" },
        ],
        textFormatting: [
            { command: "Bold", windows: "Control + B", mac: "Cmd + B" },
            { command: "Italicize", windows: "Control + I", mac: "Cmd + I" },
            { command: "Underline", windows: "Control + U", mac: "Cmd + U" },
            { command: "Strikethrough", windows: "Control + Shift + S", mac: "Cmd + Shift + S" },
            { command: "Highlight", windows: "Control + Shift + H", mac: "Cmd + Shift + H" },
            { command: "Code", windows: "Control + E", mac: "Cmd + E" },
        ],
        paragraphFormatting: [
            { command: "Apply normal text style", windows: "Control + Alt + 0", mac: "Cmd + Alt + 0" },
            { command: "Apply heading style 1", windows: "Control + Alt + 1", mac: "Cmd + Alt + 1" },
            { command: "Apply heading style 2", windows: "Control + Alt + 2", mac: "Cmd + Alt + 2" },
            { command: "Apply heading style 3", windows: "Control + Alt + 3", mac: "Cmd + Alt + 3" },
            { command: "Apply heading style 4", windows: "Control + Alt + 4", mac: "Cmd + Alt + 4" },
            { command: "Apply heading style 5", windows: "Control + Alt + 5", mac: "Cmd + Alt + 5" },
            { command: "Apply heading style 6", windows: "Control + Alt + 6", mac: "Cmd + Alt + 6" },
            { command: "Ordered list", windows: "Control + Shift + 7", mac: "Cmd + Shift + 7" },
            { command: "Bullet list", windows: "Control + Shift + 8", mac: "Cmd + Shift + 8" },
            { command: "Task list", windows: "Control + Shift + 9", mac: "Cmd + Shift + 9" },
            { command: "Blockquote", windows: "Control + Shift + B", mac: "Cmd + Shift + B" },
            { command: "Left align", windows: "Control + Shift + L", mac: "Cmd + Shift + L" },
            { command: "Center align", windows: "Control + Shift + E", mac: "Cmd + Shift + E" },
            { command: "Right align", windows: "Control + Shift + R", mac: "Cmd + Shift + R" },
            { command: "Justify", windows: "Control + Shift + J", mac: "Cmd + Shift + J" },
            { command: "Code block", windows: "Control + Alt + C", mac: "Cmd + Alt + C" },
            { command: "Subscript", windows: "Control + ,", mac: "Cmd + ," },
            { command: "Superscript", windows: "Control + .", mac: "Cmd + ." },
        ],
        textSelection: [
            { command: "Select all", windows: "Control + A", mac: "Cmd + A" },
            { command: "Extend selection one character to left", windows: "Shift + ←", mac: "Shift + ←" },
            { command: "Extend selection one character to right", windows: "Shift + →", mac: "Shift + →" },
            { command: "Extend selection one line up", windows: "Shift + ↑", mac: "Shift + ↑" },
            { command: "Extend selection one line down", windows: "Shift + ↓", mac: "Shift + ↓" },
        ],
    };

    const faqItems = [
        { question: "How do I create a new document?", answer: "There's no need to create a new document. Documents are automatically created for eact date." },
        { question: "Can I collaborate in real-time?", answer: "Yes, click 'Share' and enter collaborators' email addresses." },
        // Add more FAQ items as needed
    ];

    const tutorials = [
        { title: "Getting Started", description: "Learn the basics of our application." },
        { title: "Advanced Features", description: "Explore powerful productivity features." },
        // Add more tutorials as needed
    ];

    return (
        <Container fluid className="d-flex flex-column vh-100 pt-5 p-0 m-0 overflow-hidden">
            {/* Header Section */}
            <div className="flex-grow-1 overflow-auto ps-5 pe-5">
                <Link to="/Journal" className="d-inline-flex align-items-center mb-3 themed-text text-decoration-none">
                    <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                    Back to Journal
                </Link>
                <div className="ps-5 pe-5">
                    <h1 className="display-4 mb-5">Help Center</h1>
                </div>

                {/* Main Content */}
                <Section title="Video Tutorials">
                    <Container fluid>
                        <Row xs={1} md={2} lg={3} className="g-4">
                            {tutorials.map((tutorial, index) => (
                                <Col key={index}>
                                    <VideoTutorial {...tutorial} />
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </Section>

                <Section title="Keyboard Shortcuts">
                    <KeyboardShortcutsAccordion shortcuts={shortcuts} />
                </Section>

                <Section title="Frequently Asked Questions">
                    <FAQ items={faqItems} />
                </Section>
            </div>

            {/* Footer */}
            <AppFooter />
        </Container>
    );


};

export default HelpPage;