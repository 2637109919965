import React, { useEffect, useState } from 'react';
import { ListGroup, Button, ProgressBar, Card, Row, Col, Badge, Alert, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHdd, faArrowRight, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from "../../../contexts/AuthContext";
import NEW_JournalApiService from '../../../services/NEW_JournalApiService';
import { getStoragePlans, getStorageSubscription } from '../../../services/storageApi';
import subscriptionApiService from "../../../services/subscriptionApiService";
import PayPalSubscriptionButtonAccount from "../components/PayPalSubscriptionButtonAccount"

const StorageTab = ({ storage, subscription }) => {
    const [diskUsage, setDiskUsage] = useState(null);
    const [error, setError] = useState(null);
    const [plans, setPlans] = useState([]);
    const [userSubscription, setUserSubscription] = useState(null);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [showPayPalModal, setShowPayPalModal] = useState(false);
    const [showChangeModal, setShowChangeModal] = useState(false);
    const [agreementAccepted, setAgreementAccepted] = useState(true);
    const [isChangingPlan, setIsChangingPlan] = useState(false);
    const [baseStorageGB, setBaseStorageGB] = useState(null);
    const { user } = useAuth();

    const isTrialUser = subscription?.is_trial;

    const fetchData = async () => {
        console.log("APP subscription= ", subscription);
        if (user?.username) {
            try {
                const [usage, storagePlans, subscriptionData, baseStorage] = await Promise.all([
                    NEW_JournalApiService.getDiskUsage(user.username),
                    getStoragePlans(),
                    getStorageSubscription(subscription.user_id).catch(err => {
                        if (err.message !== 'No active storage subscription found for this user') {
                            throw err;
                        }
                        return null;
                    }),
                    subscriptionApiService.getPlanBaseStorage(subscription.plan_id)
                ]);

                setDiskUsage(usage);
                setBaseStorageGB(baseStorage);

                // Create base storage plan
                const basePlan = {
                    id: 'base-storage',
                    name: 'Base Storage',
                    description: `Base Storage (${baseStorage}GB)`,
                    base_storage_gb: baseStorage,
                    current_price: 0,
                    billing_cycle: 'month',
                    is_base_plan: true
                };

                // Add base plan to the beginning of the plans array
                setPlans([basePlan, ...storagePlans]);
                setUserSubscription(subscriptionData);
                setError(null);
            } catch (err) {
                setError(err.message);
                console.error('Error fetching data:', err);
            }
        }
    };

    useEffect(() => {
        fetchData();
    }, [user?.username, user?.id]);

    const calculateUsagePercentage = () => {
        if (!diskUsage) return 0;
        return (diskUsage.user_current_usage / diskUsage.max_disk_usage) * 100;
    };

    const getProgressVariant = (percentage) => {
        if (percentage >= 90) return 'danger';
        if (percentage >= 70) return 'warning';
        return 'success';
    };

    const formatPrice = (price) => {
        if (price === null) return 'Contact us';
        return price === 0 ? 'Included' : `$${price.toFixed(2)}`;
    };

    const formatDate = (date) => {
        return new Date(date).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    const handleSubscribe = (plan) => {
        setSelectedPlan(plan);
        if (plan.is_base_plan) {
            // If switching to base plan and user has a paid subscription
            if (userSubscription) {
                setShowChangeModal(true);
            }
        } else {
            // Regular paid plan flow
            if (userSubscription) {
                setShowChangeModal(true);
            } else {
                setShowPayPalModal(true);
            }
        }
    };

    const handleSubscriptionSuccess = () => {
        setShowPayPalModal(false);
        setSelectedPlan(null);
        fetchData();
    };

    const handleChangePlan = async () => {
        try {
            setIsChangingPlan(true);
            setError(null);

            if (selectedPlan.is_base_plan) {
                // Cancel the current storage subscription
                await subscriptionApiService.cancelStorageSubscription("");
            } else {
                // Change to a different paid plan
                const response = await subscriptionApiService.changeStorageSubscriptionPlan(
                    selectedPlan.paypal_plan_id,
                    selectedPlan.id
                );
                if (response.status !== 'success') {
                    throw new Error('Failed to change subscription plan');
                }
            }

            setShowChangeModal(false);
            setSelectedPlan(null);
            await fetchData();
        } catch (err) {
            setError(err.message || 'Failed to change subscription plan. Please try again.');
            console.error('Error changing subscription plan:', err);
        } finally {
            setIsChangingPlan(false);
        }
    };

    const getCurrentStorageGB = () => {
        if (userSubscription) {
            return userSubscription.storage_gb;
        }
        return baseStorageGB;
    };

    const renderCurrentSubscription = () => {
        if (!baseStorageGB) return null;

        return (
            <Card className="mb-4 border-success">
                <Card.Header className="bg-success text-white">
                    <FontAwesomeIcon icon={faCheckCircle} className="me-2" />
                    Current Storage Plan
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col md={6}>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <strong>Plan:</strong> {userSubscription ? userSubscription.plan_name : 'Base Storage'}
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <strong>Storage:</strong> {getCurrentStorageGB()}GB
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                        <Col md={6}>
                            {userSubscription && (
                                <ListGroup variant="flush">
                                    <ListGroup.Item>
                                        <strong>Start Date:</strong> {formatDate(userSubscription.start_date)}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <strong>End Date:</strong> {formatDate(userSubscription.end_date)}
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <strong>Auto-renew:</strong> {userSubscription.auto_renew ? 'Yes' : 'No'}
                                    </ListGroup.Item>
                                </ListGroup>
                            )}
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        );
    };

    const renderChangeModal = () => {
        const isBasePlan = selectedPlan?.is_base_plan;
        const currentUsageGB = diskUsage ? (diskUsage.user_current_usage / 1000000000) : 0;
        const exceedsNewLimit = currentUsageGB > (isBasePlan ? baseStorageGB : selectedPlan?.base_storage_gb);

        return (
            <Modal
                show={showChangeModal}
                onHide={() => !isChangingPlan && setShowChangeModal(false)}
                centered
            >
                <Modal.Header closeButton={!isChangingPlan}>
                    <Modal.Title>
                        {isBasePlan ? 'Revert to Base Storage' : 'Change Storage Plan'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error && (
                        <Alert variant="danger" className="mb-3">
                            {error}
                        </Alert>
                    )}
                    <p>
                        {isBasePlan
                            ? 'Are you sure you want to cancel your paid storage subscription and revert to the base storage plan?'
                            : `Are you sure you want to change your storage plan from ${userSubscription?.plan_name} to ${selectedPlan?.name}?`
                        }
                    </p>
                    <Alert variant="info">
                        Your new storage limit will be {selectedPlan?.base_storage_gb}GB.
                        {exceedsNewLimit && (
                            <div className="mt-2 text-danger">
                                Warning: Your current usage ({currentUsageGB.toFixed(2)}GB) exceeds the new plan's limit.
                                Please reduce your storage usage (by deleting images or other content) before {isBasePlan ? 'reverting to base storage' : 'downgrading'}.
                            </div>
                        )}
                    </Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowChangeModal(false)}
                        disabled={isChangingPlan}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleChangePlan}
                        disabled={isChangingPlan || exceedsNewLimit}
                    >
                        {isChangingPlan ? (
                            <>
                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                {isBasePlan ? 'Reverting to Base Storage...' : 'Changing Plan...'}
                            </>
                        ) : (
                            isBasePlan ? 'Confirm Revert' : 'Confirm Change'
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    };

    return (
        <div className="p-3">
            {error ? (
                <div className="text-danger mb-3">{error}</div>
            ) : (
                <>
                    {isTrialUser && (
                        <Alert variant="warning" className="mb-4">
                            Message: You must have a paid subscription before creating a storage subscription. See the subscription tab.
                        </Alert>
                    )}

                    {renderCurrentSubscription()}

                    {diskUsage && (
                        <div className="mb-4">
                            <h6 className="mb-2">Disk Usage: {calculateUsagePercentage().toFixed(2)}%</h6>
                            <ProgressBar
                                now={calculateUsagePercentage()}
                                variant={getProgressVariant(calculateUsagePercentage())}
                                className="mb-2"
                            />
                        </div>
                    )}

                    <h5 className="mb-4">Available Storage Plans</h5>
                    <Row className="g-4">
                        {plans.map((plan) => (
                            <Col key={plan.id} xs={12} md={6} lg={4}>
                                <Card className={`h-100 ${(!userSubscription && plan.is_base_plan) || userSubscription?.plan_name === plan.name ? 'border-success' : ''}`}>
                                    <Card.Body className="d-flex flex-column">
                                        <div className="d-flex justify-content-between align-items-start mb-2">
                                            <Card.Title className="mb-0">{plan.description}</Card.Title>
                                            {plan.is_trial_only && (
                                                <Badge bg="info" className="ms-2">Trial Only</Badge>
                                            )}
                                            {((!userSubscription && plan.is_base_plan) || userSubscription?.plan_name === plan.name) && (
                                                <Badge bg="success" className="ms-2">Current Plan</Badge>
                                            )}
                                        </div>
                                        <div className="small text-muted mb-3">
                                            <div>Storage: {plan.base_storage_gb}GB</div>
                                            <div>Billing: {plan.billing_cycle}</div>
                                            {plan.trial_period_days > 0 && (
                                                <div>{plan.trial_period_days} days trial available</div>
                                            )}
                                        </div>
                                        <div className="mt-auto">
                                            <div className="text-success fw-bold mb-2 text-end">
                                                {formatPrice(plan.current_price)}{plan.current_price !== 0 && plan.current_price !== null && `/${plan.billing_cycle}`}
                                            </div>
                                            <Button
                                                variant={(!userSubscription && plan.is_base_plan) || userSubscription?.plan_name === plan.name ? "success" : "outline-primary"}
                                                className="w-100"
                                                disabled={(!userSubscription && plan.is_base_plan) || userSubscription?.plan_name === plan.name || isTrialUser}
                                                onClick={() => handleSubscribe(plan)}
                                            >
                                                {(!userSubscription && plan.is_base_plan) || userSubscription?.plan_name === plan.name ? 'Current Plan' :
                                                    plan.is_base_plan ? 'Revert to Base Storage' :
                                                        userSubscription ? 'Change to This Plan' : 'Subscribe'}
                                                {userSubscription?.plan_name !== plan.name && !plan.is_base_plan && (
                                                    <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                                                )}
                                            </Button>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>

                    {storage && (
                        <ListGroup variant="flush" className="mt-4">
                            <ListGroup.Item>
                                <strong>Last Updated:</strong>{' '}
                                {new Date(storage.last_updated).toLocaleString()}
                            </ListGroup.Item>
                        </ListGroup>
                    )}

                    {/* PayPal Subscription Modal - Only shown for new paid subscriptions */}
                    <Modal
                        show={showPayPalModal}
                        onHide={() => setShowPayPalModal(false)}
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Subscribe to {selectedPlan?.description}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {selectedPlan && (
                                <PayPalSubscriptionButtonAccount
                                    TaskPlexPlanId={selectedPlan.id}
                                    PayPalPlanId={selectedPlan.paypal_plan_id}
                                    planName={selectedPlan.name}
                                    email={user.email}
                                    disabled={false}
                                    agreementAccepted={agreementAccepted}
                                    onAcceptAgreement={() => setAgreementAccepted(true)}
                                    agreementContent={{ content_url: selectedPlan.agreement_url }}
                                    onSuccess={handleSubscriptionSuccess}
                                />
                            )}
                        </Modal.Body>
                    </Modal>

                    {/* Change/Revert Plan Modal */}
                    {renderChangeModal()}
                </>
            )}
        </div>
    );
};

export default StorageTab;