import 'bootstrap/dist/css/bootstrap.min.css';
// Footer.jsx
// import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const PublicFooter = () => {
    return (
        <footer className="bg-dark text-white py-3">
            <Container>
                <Row className="align-items-center">
                    <Col md={6} className="text-center text-md-start">
                        <p className="mb-0">© 2024 {import.meta.env.VITE_APP_SITE_NAME} - TaskPlex<sup>®</sup>. All rights reserved.</p>
                    </Col>
                    <Col md={6} className="text-center text-md-end mt-3 mt-md-0">
                        <a
                            href="/terms_and_conditions_v2.0.pdf"
                            className="text-white me-3 text-decoration-none"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Terms & Conditions
                        </a>
                        <a
                            href="/privacy_policy_v1.0.pdf"
                            className="text-white text-decoration-none"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Privacy Policy
                        </a>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default PublicFooter;
