
// Suppress specific warnings
const originalWarn = console.warn;
console.warn = (message, ...args) => {
  if (message.includes('React Router Future Flag Warning')) {
    return;
  }
  originalWarn(message, ...args);
};



// import React from 'react'
import ReactDOM from 'react-dom/client'
import { ToastContainer } from 'react-toastify';
import App from './App.jsx'

// Import Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
// Import Bootstrap Bundle JS
import 'bootstrap/dist/js/bootstrap.bundle.min';

// Import your custom CSS (adjust the path as needed)
import './assets/css/themes.css';
import './assets/css/CalendarGrid.css';
import './assets/css/connectome-dark-blue-theme.css';
import './assets/css/connectome-dark-theme.css';
import './assets/css/connectome-styles.css';
import './assets/css/Tiptap.css';

ReactDOM.createRoot(document.getElementById('root')).render(
  // <React.StrictMode>
  <>
    <App />
    <ToastContainer
      autoClose={1000}
    />
  </>
  // </React.StrictMode>,
)