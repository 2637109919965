// SubscriptionTab.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ListGroup, Button, Badge, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { ManageSubscriptionModal } from '../components/ManageSubscriptionModal';
import { ChangePlanModal } from '../components/ChangePlanModal';
import { CancelSubscriptionModal } from '../components/CancelSubscriptionModal';
import { PRICING_DATA } from '../../SubscriptionPlans/PricingData';
import subscriptionApiService from '../../../services/subscriptionApiService';
import { useAuth } from "../../../contexts/AuthContext";

export const SubscriptionTab = ({ subscription, onSubscriptionUpdate }) => {
    const [showManageModal, setShowManageModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showChangePlanModal, setShowChangePlanModal] = useState(false);
    const [cancelReason, setCancelReason] = useState('');
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [changingPlan, setChangingPlan] = useState(false);
    const [notification, setNotification] = useState(null);

    const navigate = useNavigate();
    const { user, setVerifiedUser } = useAuth();

    // Use either active or cancelled subscription data
    const subscriptionData = subscription

    const showNotification = (type, message) => {
        setNotification({ type, message });
        setTimeout(() => setNotification(null), 5000);
    };

    const handlePlanSelect = (planId, planName) => {
        setSelectedPlan({ id: planId, name: planName });
        setShowChangePlanModal(true);
        setShowManageModal(false);
    };

    const handlePlanChange = async () => {
        if (!selectedPlan) {
            setError('Please select a plan');
            return;
        }

        setLoading(true);
        setError('');
        setNotification(null);

        try {
            const planDetails = await subscriptionApiService.getPayPalPlanDetails(selectedPlan.id);

            if (parseInt(subscriptionData.plan_id) === 1) {
                setVerifiedUser({
                    ...user,
                    TaskPlexPlanId: planDetails.plan_id,
                    paypalPlanId: planDetails.paypal_plan_id,
                    paypalProductId: planDetails.paypal_product_id,
                    planName: selectedPlan.name
                });
                setShowChangePlanModal(false);
                navigate('/PaymentAccount');
                return;
            }

            const changeResponse = await subscriptionApiService.changeSubscriptionPlan(
                planDetails.paypal_plan_id,
                parseInt(selectedPlan.id)
            );

            if (changeResponse?.subscription_id) {
                setChangingPlan(true);
                showNotification('info', 'Processing plan change...');

                let attempts = 0;
                const maxAttempts = 40;

                const pollInterval = setInterval(async () => {
                    try {
                        const statusResponse = await subscriptionApiService.checkStatusChange(
                            changeResponse.subscription_id
                        );

                        if (statusResponse.change_type === "PLAN_CHANGE") {
                            if (statusResponse.status === "CHANGE_CONFIRMED") {
                                clearInterval(pollInterval);
                                setChangingPlan(false);
                                setShowChangePlanModal(false);
                                showNotification('success', 'Plan change successful!');
                                if (onSubscriptionUpdate) {
                                    await onSubscriptionUpdate();
                                }
                                return;
                            }
                            else if (statusResponse.status === "CHANGE_FAILED") {
                                clearInterval(pollInterval);
                                setChangingPlan(false);
                                showNotification('danger', 'Plan change failed. Please try again.');
                                return;
                            }
                        }

                        attempts++;
                        if (attempts >= maxAttempts) {
                            clearInterval(pollInterval);
                            setChangingPlan(false);
                            showNotification('danger', 'Plan change timeout. Please check your subscription status.');
                        }
                    } catch (error) {
                        clearInterval(pollInterval);
                        setChangingPlan(false);
                        showNotification('danger', 'Error checking plan change status');
                        console.error('Status check error:', error);
                    }
                }, 3000);
            }

        } catch (error) {
            showNotification('danger', 'Failed to initiate plan change. Please try again.');
            console.error('Plan change error:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCancel = async () => {
        setLoading(true);
        setError('');
        setNotification(null);

        try {
            await subscriptionApiService.cancelAllSubscriptions(cancelReason);
            setShowCancelModal(false);
            showNotification('success', 'Subscription cancellation initiated. Will remain active until end of billing period.');
            if (onSubscriptionUpdate) {
                await onSubscriptionUpdate();
            }
        } catch (err) {
            showNotification('danger', 'Failed to cancel subscription. Please try again.');
            console.error('Cancellation error:', err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {notification && (
                <div style={{ position: 'relative', zIndex: 1050 }}>
                    <Alert variant={notification.type} dismissible onClose={() => setNotification(null)} className="mb-3">
                        {notification.message}
                    </Alert>
                </div>
            )}

            <ListGroup variant="flush">
                <ListGroup.Item>
                    <strong>Plan Name:</strong> {subscriptionData?.plan_name || 'N/A'} {' '}
                    {subscriptionData?.status && (
                        <Badge bg={subscriptionData.status === 'active' ? 'primary' : 'warning'}>
                            {subscriptionData.status}
                        </Badge>
                    )}
                </ListGroup.Item>
                <ListGroup.Item>
                    <strong>Status:</strong> {' '}
                    <Badge bg={subscriptionData?.status === 'active' ? 'success' : 'warning'}>
                        {subscriptionData?.status}
                    </Badge>
                </ListGroup.Item>
                <ListGroup.Item>
                    <strong>Start Date:</strong> {subscriptionData?.start_date ? new Date(subscriptionData.start_date).toLocaleDateString() : 'N/A'}
                </ListGroup.Item>
                <ListGroup.Item>
                    <strong>End Date:</strong> {subscriptionData?.end_date ? new Date(subscriptionData.end_date).toLocaleDateString() : 'N/A'}
                </ListGroup.Item>
                <ListGroup.Item>
                    <strong>Auto-renew:</strong> {subscriptionData?.status === 'cancelled' ? 'No' : (subscription ? 'Yes' : 'No')}
                </ListGroup.Item>
                <ListGroup.Item>
                    <strong>Trial:</strong> {subscriptionData?.is_trial ? 'Yes' : 'No'}
                </ListGroup.Item>
            </ListGroup>

            <Button
                variant="outline-primary"
                className="mt-3 me-2"
                onClick={() => setShowManageModal(true)}
                disabled={!subscriptionData}
            >
                <FontAwesomeIcon icon={faFileInvoiceDollar} className="me-2" />
                Manage Subscription
            </Button>

            <ManageSubscriptionModal
                show={showManageModal}
                onHide={() => setShowManageModal(false)}
                error={error}
                availablePlans={PRICING_DATA}
                subscriptionId={subscriptionData?.plan_id}
                loading={loading}
                onPlanSelect={handlePlanSelect}
                onShowCancelModal={() => {
                    setShowManageModal(false);
                    setShowCancelModal(true);
                }}
                isTrialSubscription={subscriptionData?.is_trial}
            />

            <ChangePlanModal
                show={showChangePlanModal}
                onHide={() => {
                    setShowChangePlanModal(false);
                    setNotification(null);
                    if (changingPlan) {
                        setChangingPlan(false);
                    }
                }}
                error={error}
                selectedPlan={selectedPlan}
                loading={loading || changingPlan}
                onConfirm={handlePlanChange}
                subscription={subscriptionData}
            />

            <CancelSubscriptionModal
                show={showCancelModal}
                onHide={() => setShowCancelModal(false)}
                error={error}
                cancelReason={cancelReason}
                onReasonChange={setCancelReason}
                loading={loading}
                onConfirm={handleCancel}
            />
        </>
    );
};

export default SubscriptionTab;