// src/components/CreateTrialUser.js

import { useState, useEffect } from 'react';
import { Form, Button, Alert, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus, faUser, faLock, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from 'react-router-dom';
import LoginApiService from '../services/LoginApiService';
// import ConnectomeSVG from '../assets/images/connectome-large.svg';
import AmbientBackground from './AmbientBackground';
import '../assets/css/LoginBackground.css';
import PublicFooter from "./PublicFooter"
import { useAuth } from '../contexts/AuthContext';

const CreateTrialUser = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);
    const [focusedField, setFocusedField] = useState(null);
    const [usernameError, setUsernameError] = useState('');
    const [passwordStrength, setPasswordStrength] = useState({
        length: false,
        lowercase: false,
        uppercase: false,
        number: false,
        special: false
    });

    const navigate = useNavigate();
    const { setVerifiedUser } = useAuth();

    const checkPasswordStrength = (password) => {
        setPasswordStrength({
            length: password.length >= 8,
            lowercase: /[a-z]/.test(password),
            uppercase: /[A-Z]/.test(password),
            number: /[0-9]/.test(password),
            special: /[@$!%*?&-]/.test(password)
        });
    };

    useEffect(() => {
        checkPasswordStrength(password);
    }, [password]);

    const validateUsername = (username) => {
        return /^[a-zA-Z][a-zA-Z0-9]*$/.test(username);
    };

    const handleUsernameChange = (e) => {
        const newUsername = e.target.value;
        setUsername(newUsername);
        if (newUsername === '') {
            setUsernameError('');
        } else if (!validateUsername(newUsername)) {
            setUsernameError('Username must start with a letter and can only contain letters and numbers');
        } else {
            setUsernameError('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');
        setLoading(true);

        if (!validateUsername(username)) {
            setError('Username must start with a letter and contain only letters and numbers');
            setLoading(false);
            return;
        }

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            setLoading(false);
            return;
        }

        if (!Object.values(passwordStrength).every(Boolean)) {
            setError('Password does not meet all requirements');
            setLoading(false);
            return;
        }

        try {
            const userData = { username, password };
            console.log("GOTO: createUserTrialUser");
            const response = await LoginApiService.createUserTrialUser(userData);
            console.log("createUserTrialUser RESPONSE= ", response);
            setSuccess('User created successfully. You can now log in.');
            console.log("NEXT: setVerifiedUser");

            setVerifiedUser({
                email: response.user.email,
                username: response.user.username,
            });

            setTimeout(() => navigate('/login'), 3000);
            console.log("Navigate next");
            // navigate('/login')
            console.log("Should have navigated");
        } catch (error) {
            setError(error.message || 'User creation failed');
        } finally {
            setLoading(false);
        }
    };


    const renderPasswordStrengthFeedback = () => {
        return (
            <div className="password-strength-feedback mt-2">
                <p className="mb-1">Password must contain:</p>
                <ul className="list-unstyled">
                    <li className={passwordStrength.length ? 'text-success' : 'text-danger'}>
                        {passwordStrength.length ? '✓' : '✗'} At least 8 characters
                    </li>
                    <li className={passwordStrength.lowercase ? 'text-success' : 'text-danger'}>
                        {passwordStrength.lowercase ? '✓' : '✗'} Lowercase letter
                    </li>
                    <li className={passwordStrength.uppercase ? 'text-success' : 'text-danger'}>
                        {passwordStrength.uppercase ? '✓' : '✗'} Uppercase letter
                    </li>
                    <li className={passwordStrength.number ? 'text-success' : 'text-danger'}>
                        {passwordStrength.number ? '✓' : '✗'} Number
                    </li>
                    <li className={passwordStrength.special ? 'text-success' : 'text-danger'}>
                        {passwordStrength.special ? '✓' : '✗'} Special character (@$!%*?&-)
                    </li>
                </ul>
            </div>
        );
    };

    const togglePasswordVisibility = (field) => {
        if (field === 'password') {
            setShowPassword(!showPassword);
        } else if (field === 'confirmPassword') {
            setShowConfirmPassword(!showConfirmPassword);
        }
    };

    return (
        <div>
            <Container fluid className="min-vh-100 d-flex align-items-center justify-content-center login-background py-4">
                <AmbientBackground />
                <Row className="justify-content-md-center w-100">
                    <Col xs={12} md={6} lg={4}>
                        <div className="bg-white p-4 rounded shadow-lg login-card" style={{ border: '1px solid rgba(76, 175, 80, 0.3)' }}>
                            {/* ... (rest of the JSX remains the same) */}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3 position-relative">
                                    <Form.Label htmlFor="username">Username</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="username"
                                        name="username"
                                        placeholder="Enter username (letters and numbers only)"
                                        value={username}
                                        onChange={handleUsernameChange}
                                        onFocus={() => setFocusedField('username')}
                                        onBlur={() => setFocusedField(null)}
                                        disabled={loading}
                                        required
                                        className={`border-success ${usernameError ? 'is-invalid' : ''}`}
                                        autoComplete="username"
                                    />
                                    {usernameError && <Form.Control.Feedback type="invalid">{usernameError}</Form.Control.Feedback>}
                                    <FontAwesomeIcon
                                        icon={faUser}
                                        className={`form-icon ${focusedField === 'username' ? 'text-success' : 'text-muted'}`}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3 position-relative">
                                    <Form.Label htmlFor="new-password">Password</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type={showPassword ? "text" : "password"}
                                            id="new-password"
                                            name="new-password"
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            onFocus={() => setFocusedField('password')}
                                            onBlur={() => setFocusedField(null)}
                                            disabled={loading}
                                            required
                                            className="border-success"
                                            autoComplete="new-password"
                                        />
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => togglePasswordVisibility('password')}
                                            type="button"
                                        >
                                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                        </Button>
                                    </div>
                                </Form.Group>

                                {renderPasswordStrengthFeedback()}

                                <Form.Group className="mb-3 position-relative">
                                    <Form.Label htmlFor="new-password-confirm">Confirm Password</Form.Label>
                                    <div className="input-group">
                                        <Form.Control
                                            type={showConfirmPassword ? "text" : "password"}
                                            id="new-password-confirm"
                                            name="new-password-confirm"
                                            placeholder="Confirm Password"
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            onFocus={() => setFocusedField('confirmPassword')}
                                            onBlur={() => setFocusedField(null)}
                                            disabled={loading}
                                            required
                                            className="border-success"
                                            autoComplete="new-password"
                                        />
                                        <Button
                                            variant="outline-secondary"
                                            onClick={() => togglePasswordVisibility('confirmPassword')}
                                            type="button"
                                        >
                                            <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                                        </Button>
                                    </div>
                                </Form.Group>

                                <Button variant="success" type="submit" disabled={loading} className="w-100 py-2 mb-3">
                                    <FontAwesomeIcon icon={faUserPlus} className="me-2" />
                                    {loading ? 'Creating account...' : 'Create Account'}
                                </Button>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
            <PublicFooter />
        </div>
    );
};

export default CreateTrialUser;
