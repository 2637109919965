import { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Alert, ProgressBar } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faLock, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import LoginApiService from '../services/LoginApiService';

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [focusedField, setFocusedField] = useState(null);
    const [token, setToken] = useState('');
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [passwordFeedback, setPasswordFeedback] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const urlToken = new URLSearchParams(location.search).get('token');
        if (urlToken) {
            setToken(urlToken);
        } else {
            setError('Invalid or missing reset token. Please request a new password reset link.');
        }
    }, [location]);

    const getPasswordStrengthColor = (strength) => {
        if (strength < 25) return 'danger';
        if (strength < 50) return 'warning';
        if (strength < 75) return 'info';
        if (strength < 100) return 'primary';
        return 'success';
    };

    const getPasswordStrengthLabel = (strength) => {
        if (strength < 25) return 'Very Weak';
        if (strength < 50) return 'Weak';
        if (strength < 75) return 'Moderate';
        if (strength < 100) return 'Strong';
        return 'Very Strong';
    };

    const checkPasswordStrength = (pass) => {
        let strength = 0;
        let feedback = [];

        if (pass.length >= 8) {
            strength += 25;
        } else {
            feedback.push('At least 8 characters');
        }

        if (/[A-Z]/.test(pass)) {
            strength += 25;
        } else {
            feedback.push('At least one uppercase letter');
        }

        if (/[a-z]/.test(pass)) {
            strength += 25;
        } else {
            feedback.push('At least one lowercase letter');
        }

        if (/\d/.test(pass)) {
            strength += 12.5;
        } else {
            feedback.push('At least one number');
        }

        if (/[!@#$%^&*(),.?":{}|<>]/.test(pass)) {
            strength += 12.5;
        } else {
            feedback.push('At least one special character');
        }

        setPasswordStrength(strength);
        setPasswordFeedback(feedback.join(', '));
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        checkPasswordStrength(newPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess('');

        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            setLoading(false);
            return;
        }

        if (passwordStrength < 100) {
            setError('Password does not meet all requirements.');
            setLoading(false);
            return;
        }

        try {
            const response = await LoginApiService.resetPassword(token, password);
            setSuccess(response.message || 'Your password has been successfully reset. You can now log in with your new password.');
            setTimeout(() => navigate('/login'), 3000);
        } catch (error) {
            setError(error.response?.data?.detail || 'An error occurred. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Container fluid className="min-vh-100 d-flex align-items-center justify-content-center login-background py-4">
                <div className="ambient-shapes">{/* Add your ambient shapes here */}</div>
                <Row className="justify-content-md-center w-100">
                    <Col xs={12} md={6} lg={4}>
                        <div className="bg-white p-4 rounded shadow-lg login-card" style={{ border: '1px solid rgba(76, 175, 80, 0.3)' }}>
                            <div className="text-center mb-4">
                                <div className="d-flex justify-content-begin">
                                    <Link to="/" className="text-success"><FontAwesomeIcon icon={faArrowLeftLong} className="me-2" /></Link>
                                </div>
                                <Link to="/" style={{ textDecoration: 'none' }}><h2 className="font-weight-bold text-success">TaskPlex<sup>®</sup></h2></Link>
                                <p className="text-muted">Enter your new password</p>
                            </div>

                            {error && <Alert variant="danger">{error}</Alert>}
                            {success && <Alert variant="success">{success}</Alert>}

                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3 position-relative" controlId="formBasicPassword">
                                    <Form.Label>New Password</Form.Label>
                                    <div className="position-relative">
                                        <Form.Control
                                            type={showPassword ? "text" : "password"}
                                            placeholder="Enter new password"
                                            value={password}
                                            onChange={handlePasswordChange}
                                            onFocus={() => setFocusedField('password')}
                                            onBlur={() => setFocusedField(null)}
                                            disabled={loading}
                                            required
                                            className="border-success"
                                        />
                                        <FontAwesomeIcon
                                            icon={faLock}
                                            className={`form-icon ${focusedField === 'password' ? 'text-success' : 'text-muted'}`}
                                            style={{
                                                position: 'absolute',
                                                top: '-8px',
                                                left: '50%',
                                                transform: 'translateX(-50%)'
                                            }}
                                        />
                                        <FontAwesomeIcon
                                            icon={showPassword ? faEyeSlash : faEye}
                                            className="form-icon text-muted"
                                            style={{
                                                position: 'absolute',
                                                right: '10px',
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => setShowPassword(!showPassword)}
                                        />
                                    </div>
                                </Form.Group>


                                <ProgressBar
                                    now={passwordStrength}
                                    variant={getPasswordStrengthColor(passwordStrength)}
                                    className="mb-2"
                                />
                                <p className="text-muted small mb-3">
                                    {passwordFeedback || `Password strength: ${getPasswordStrengthLabel(passwordStrength)}`}
                                </p>

                                <Form.Group className="mb-3 position-relative" controlId="formBasicConfirmPassword">
                                    <Form.Label>Confirm New Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Confirm new password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        onFocus={() => setFocusedField('confirmPassword')}
                                        onBlur={() => setFocusedField(null)}
                                        disabled={loading}
                                        required
                                        className="border-success"
                                    />
                                    <FontAwesomeIcon
                                        icon={faLock}
                                        className={`form-icon ${focusedField === 'confirmPassword' ? 'text-success' : 'text-muted'}`}
                                    />
                                </Form.Group>

                                <Button variant="success" type="submit" disabled={loading || passwordStrength < 100} className="w-100 py-2 mb-3">
                                    {loading ? 'Resetting...' : 'Reset Password'}
                                </Button>
                            </Form>

                            <div className="mt-3 text-center">
                                Remember your password? <Link to="/login" className="text-success">Log in</Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ResetPassword;