// components/PricingFeature.jsx
// import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const PricingFeature = ({ feature }) => (
    <li className="mb-2">
        <FontAwesomeIcon icon={faCheck} className="text-success me-2" />
        {feature}
    </li>
);

export default PricingFeature;