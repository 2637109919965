import React from 'react';
import { Modal, Alert, Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faCancel } from '@fortawesome/free-solid-svg-icons';
import "../../../assets/css/LoginBackground.css"
import { PricingTier } from './PricingTier';

export const ManageSubscriptionModal = ({
    show,
    onHide,
    error,
    availablePlans,
    loading,
    onPlanSelect,
    onShowCancelModal,
    isTrialSubscription,
    active_subscriptionId
}) => {
    return (
        <Modal
            className='login-background'
            show={show}
            onHide={onHide}
            size="xl"
            fullscreen="sm-down"
            style={{
                position: 'fixed',
                zIndex: 1050,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Manage Subscription</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error && <Alert variant="danger">{error}</Alert>}

                <Container fluid>
                    <Row className="justify-content-center mb-4">
                        <Col xs={12}>
                            <h4 className="text-center mb-4">Available Plans</h4>
                        </Col>
                        {availablePlans.map((plan, index) => (
                            <Col
                                key={index}
                                xs={12}      // Full width on mobile
                                sm={6}       // Two columns on small screens
                                md={4}       // Three columns on medium and up
                                className="mb-4"
                            >
                                <PricingTier
                                    planId={plan.planId}
                                    name={plan.name}
                                    price={plan.price}
                                    features={plan.features}
                                    isPopular={plan.isPopular}
                                    isPerSeat={plan.isPerSeat}
                                    onSelectPlan={onPlanSelect}
                                    activeSubscriptionId={active_subscriptionId}
                                />
                            </Col>
                        ))}
                    </Row>

                    <Row className="mt-4">
                        <Col xs={12} className="d-grid gap-2">
                            {!isTrialSubscription && (
                                <Button
                                    variant="outline-danger"
                                    onClick={onShowCancelModal}
                                    disabled={loading}
                                >
                                    <FontAwesomeIcon icon={faCancel} className="me-2" />
                                    Cancel Subscription
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default ManageSubscriptionModal;