// AccountTab.js
import React from 'react';
import { ListGroup, Badge } from 'react-bootstrap';

export const AccountTab = ({ user_info }) => (
    <ListGroup variant="flush">
        <ListGroup.Item>
            <strong>Status:</strong> {' '}
            <Badge bg={user_info.is_active ? 'success' : 'danger'}>
                {user_info.is_active ? 'Active' : 'Inactive'}
            </Badge>
        </ListGroup.Item>
        <ListGroup.Item>
            <strong>Admin:</strong> {' '}
            <Badge bg={user_info.is_admin ? 'success' : 'secondary'}>
                {user_info.is_admin ? 'Yes' : 'No'}
            </Badge>
        </ListGroup.Item>
        <ListGroup.Item>
            <strong>Email Verified:</strong> {' '}
            <Badge bg={user_info.email_verified ? 'success' : 'warning'}>
                {user_info.email_verified ? 'Yes' : 'No'}
            </Badge>
        </ListGroup.Item>
        <ListGroup.Item>
            <strong>Two-Factor Auth:</strong> {' '}
            <Badge bg={user_info.two_factor_enabled ? 'success' : 'warning'}>
                {user_info.two_factor_enabled ? 'Enabled' : 'Disabled'}
            </Badge>
        </ListGroup.Item>
        <ListGroup.Item><strong>Created:</strong> {new Date(user_info.created_at).toLocaleDateString()}</ListGroup.Item>
        <ListGroup.Item><strong>Last Login:</strong> {user_info.last_login ? new Date(user_info.last_login).toLocaleDateString() : 'N/A'}</ListGroup.Item>
    </ListGroup>
);

export default AccountTab;